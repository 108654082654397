<template>
  <NavbarCombination :activePage="'products'"/>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img src="@/assets/home_images/首页banner背景图.png" alt="">
    <div>
      <span>
        数智化转型，全程透明监管
      </span>
      <span>
        推动传统制造业向数字化管理发展，让行业向信息化发展！
      </span>
    </div>
  </div>
  <div class="container module describe">
    <span>
      易捷ERP系统概述
    </span>
    <span class="middle-text">
      易捷ERP系统是一套高度集成、高度整合离散型制造企业的整体解决方案，其功能涵盖企业日常管理所涉及的所有业务流程，将物流、资金流、信息流有效进行整合。实现企业各部门、各流程环节上的协调管理、相互制约、相互监督，确保各部门信息传递的畅通，有效擗免信息孤岛的形成，减少企业重复劳动。为企业提供一个操作简便、灵活管理、决策智能化的管理工具，竭力协助企业降低成本、抑制风险、提升效率、优化管理
    </span>
  </div>
  <div class="new-color-module">
    <div class="container describe change-the-text">
      <span>实现信息共享，解放生产力</span>
    </div>
    <div>
      <!-- 自定义文字指示器 -->
      <div class="custom-indicators container">
        <div v-for="(name, index) in names" :key="index" class="container">
          <span class="navbar-4-box row">
            <div class="col">
              <div :class="{ active: currentIndex === index }" @mouseenter="choosePage(index)" @click="choosePage(index)">
                {{ name }}
              </div>
            </div>
          </span>
        </div>
      </div>
      <el-carousel :height="isPhone ? '500px':'550px'" class="container" indicator-position="none" @change="handleCarouselChange" ref="carousel">
        <el-carousel-item v-for="(img, index) in images" :key="index" class="img-text-box row">
          <img :src="img.url" alt="" class="col-md-6 col-12"/>
          <div class="texts-all-4 col-md-6 col-12">
            <span class="text-type-1">{{ img.text1 }}</span>
            <span class="text-type-2">{{ img.text2 }}</span>
            <div v-for="(text, index) in img.text3" :key="index">
              <img src="@/assets/ERP_images/图标-1.png">
              <span class="text-type-3 small-text">
                {{ text }}
              </span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>

  <div class="module2 have-background-img">
    <div class="container module describe change-the-text">
      <span>易捷ERP系统全流程</span>
    </div>
    <div class="container">
      <img v-if="isPhone" src="@/assets/ERP_images/流程图-移动端.png" >
      <img v-else src="@/assets/ERP_images/流程图.png" >
    </div>
  </div>

  <div class="container change-img-bottom" :class="Animate1 ? 'logobox myanimation' : 'logobox'" ref="logobox1">
    <div class="container module describe change-the-text">
      <span>部分场景应用</span>
    </div>
    <div v-if="isPhone" class="row apply-3 no-background-color">
      <img src="@/assets/ERP_images/扫码出入库.png" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          扫码出入库
        </span>
        <span class="middle-text">
          仓库人员可通过手机或PDA扫码，将产品编码、产品数量、仓库等关键信息录入ERP系统；<br>
          实现产品一物一码，从而提高物流效率和管理效率
        </span>
      </div>
    </div>
    <div v-else class="row apply-3 no-background-color">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          扫码出入库
        </span>
        <span class="middle-text">
          仓库人员可通过手机或PDA扫码，将产品编码、产品数量、仓库等关键信息录入ERP系统；<br>
          实现产品一物一码，从而提高物流效率和管理效率
        </span>
      </div>
      <img src="@/assets/ERP_images/扫码出入库.png" class="col-md-6 col-12">
    </div>
  </div>
  <div class="have-background-color change-img-bottom" :class="Animate2 ? 'logobox myanimation' : 'logobox'" ref="logobox2">
    <div class="container">
      <div class="row apply-3">
        <div class="col-md-6 col-12" style="position: relative;">
          <el-carousel class="kanban-height" indicator-position="none" @change="handleCarouselKanBanChange">
            <el-carousel-item class="kanban-height">
              <img src="@/assets/ERP_images/库存看板.gif">
            </el-carousel-item>
            <el-carousel-item class="kanban-height">
              <img src="@/assets/ERP_images/销售看板.jpg">
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="col-md-6 box-contain-2 col-12">
          <span class="text-type-1">
            大数据看板
          </span>
            <span class="middle-text">
              <p>
                <span class="black-weight">实时数据监控：</span>
                直观、易懂的图表或图形展示。随时了解到生产、销售、库存等各方面的最新情况，利于及时采取决策和调整
              </p>
              <p>
                <span class="black-weight">全面覆盖：</span>
                看板覆盖了企业运营的多个方面，实时掌握订单进度、库存情况、销售情况、财务等关键信息
              </p>
              <p>
                <span class="black-weight">流程优化：</span>
                通过数据集成、数据分析和可视化呈现，使企业各项业务流程更加高效、精确，为企业管理者提供了全面、直观的数据展示与决策支持, 实现资源的优化和利用
              </p>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="container no-background-color change-img-bottom" :class="Animate3 ? 'logobox myanimation' : 'logobox'" ref="logobox3">
    <div v-if="isPhone" class="row apply-3">
      <img src="@/assets/ERP_images/智能物料分析.png" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          智能物料分析
        </span>
        <span class="middle-text">
          自动分析需求量与库存、最大程度利用现有库存、一键预购，精准采购；一键选单，快速选择需要分析的物料；<br>
          根据业务需求，灵活配置参数使得企业更穩定的库存管理和更高效的采购，减少库存过剩和短缺
        </span>
      </div>
    </div>
    <div v-else class="row apply-3">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          智能物料分析
        </span>
        <span class="middle-text">
          自动分析需求量与库存、最大程度利用现有库存、一键预购，精准采购；一键选单，快速选择需要分析的物料；<br>
          根据业务需求，灵活配置参数使得企业更穩定的库存管理和更高效的采购，减少库存过剩和短缺
        </span>
      </div>
      <img src="@/assets/ERP_images/智能物料分析.png" class="col-md-6 col-12">
    </div>
  </div>

  <div class="have-background-img">
    <div class="container describe change-the-text-top">
      <span>案例分享</span>
    </div>
    <div v-if="isPhone" class="container cases-all">
      <div class="row">
        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/美多电器-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>美多电器</span>
              <span>"通过客户信息管理，采购、销售、仓库等多部门协同管理，打破信息壁垒，提升订单准交率，实现个业务一体化管理"</span>
            </div>
          </div>
        </div>
        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/健达通车用电子-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>健达通车用电子</span>
              <span>"通过实施易捷ERP系统，构建进销存一体化，帮助企业提升管理效率;精确的仓库管理体系，优化资源配置"</span>
            </div>
          </div>
        </div>
        <div class="case">
            <div class="benchmark-box">
              <img src="@/assets/ERP_images/卡梦帝电器-移动端.jpg" alt="erp">
              <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
                <span>卡梦帝电器</span>
                <span>"借助易捷ERP+SRM，实现对外连接供应商，对内各部门业务互联互通，产品识别码信息透明化，贴码过程由供应商完成，提高工作效率"</span>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div v-else class="container cases-all">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/美多电器.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>美多电器</span>
              <span >"通过客户信息管理，采购、销售、仓库等多部门协同管理，打破信息壁垒，提升订单准交率，实现个业务一体化管理"</span>
            </div>
          </div>
        </div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/健达通车用电子.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>健达通车用电子</span>
              <span >"通过实施易捷ERP系统，构建进销存一体化，帮助企业提升管理效率;精确的仓库管理体系，优化资源配置"</span>
            </div>
          </div>
        </div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/卡梦帝电器.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>卡梦帝电器</span>
              <span >"借助易捷ERP+SRM，实现对外连接供应商，对内各部门业务互联互通，产品识别码信息透明化，贴码过程由供应商完成，提高工作效率"</span>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>

</template>

<script>

import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};

export default {
  name: "ERPPlan",
  components: {RightNavbar, NavbarCombination},
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      Animate5: false,
      isPhone: false,
      currentIndex: 0, // 当前轮播图的索引
      currentIndexKanBan: 0, // 当前轮播图的索引
      activeIndex: 0,
      names:[
        '一体化管理','一物一码','订单一键派单','多系统可互联'
      ],
      images: [{
        url: require('@/assets/ERP_images/一体化管理.jpg'),
        text1:'信息共通共享',
        text2:'电子化的企业管理模式，高效管理协同，降低人力管理成本',
        text3:['实现不同管理模块间的数据整合和信息重组，业务信息统一管理，降低部门间信息共享的风险性，提高了工作与交付效率；',
            '跨部门跨区域之间进行实时的信息流及决策管理，提升多部门协同合作的效率；',
            '一个集多个功能模块于一体的信息化管理平台']
      },
        {
          url: require('@/assets/ERP_images/一物一码.jpg'),
          text1:'物联网时代万物互联的抓手',
          text2:'实时监控产品动态，把控产品全周期动态，对产品的追踪溯源',
          text3:['实现从成品到半成品到成品（物料）的可监控、可追溯，确保产品生产过程的质量控制；',
            '对产品进行全生命周期的追溯和数据管理，保障产品源头的可塑性；',
            '通过实时追溯批次、品质等原料信息，提升产品质量和生产效率']
        },
        {
          url: require('@/assets/ERP_images/订单一键派单.jpg'),
          text1:'【销售订单】一键生成【生产任务单】',
          text2:'销售部门与生产部门一体化集成管理，订单流转无缝衔接',
          text3:['可根据业务需求，灵活选择外协或自制；',
            '支持多种计划来源、可根据销售订单或库存备货等方式生成生产单；']
        }, {
          url: require('@/assets/ERP_images/多系统可互联.jpg'),
          text1:'一键部署数据互通',
          text2:'数据统一汇聚，统一存储，集中计算，集中管控和统一共享',
          text3:['提供一站式系统解决方案，多系统支持原生互联，一部署数据互通；',
            '每个系统又可独立运行，预留开放接口，迅速与第三方系统对接；']
        },
      ],
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener('scroll',this.scrollToTop);
    next();
  },
  methods:{
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
      this.Animate2=!isElementNotInViewport(this.$refs.logobox2)
      this.Animate3=!isElementNotInViewport(this.$refs.logobox3)
      this.Animate4=!isElementNotInViewport(this.$refs.logobox4)
      this.Animate5=!isElementNotInViewport(this.$refs.logobox5)
    },
    handleCarouselChange(currentIndex) {
      this.currentIndex = currentIndex;
    },
    handleCarouselKanBanChange(currentIndexKanBan) {
      this.currentIndexKanBan = currentIndexKanBan;
    },
    choosePage(index) {
      if (index >= 0 && index < 4) { // 确保索引在有效范围内
        this.$refs.carousel.setActiveItem(index)
      }
      console.log(this.activeIndex)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
  }
}
</script>

<style scoped>
.describe{
  display: flex;
  flex-direction: column;
  width: 90%;
}
.describe span:nth-child(1){
  font-size: calc(20px + 1.5vw);
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.change-the-text{
  margin-top: 30px;
  margin-bottom: 20px;
}
.change-the-text-top{
  padding-top: 30px;
}
.custom-indicators{
  text-align: center;
  display: flex;
}
.custom-indicators div{
  font-size: calc(8px + 1vw);
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.custom-indicators * {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}
/*.custom-indicators .active{*/
/*  color: cornflowerblue!important;*/
/*}*/
/*.custom-indicators .active::after {*/
/*  content: ""; !* 伪元素必须有内容 *!*/
/*  position: absolute; !* 相对于a元素定位 *!*/
/*  left: 0; !* 从左边开始 *!*/
/*  right: 0; !* 到右边结束 *!*/
/*  bottom: -10px; !* 下划线与文字之间的间隔 *!*/
/*  height: 4px; !* 下划线的厚度 *!*/
/*  background-color: cornflowerblue; !* 下划线的颜色 *!*/
/*  opacity: 1!important;*/
/*  transition: max-width 10s ease-in-out; !* 过渡效果 *!*/
/*}*/

.img-text-box{
  display: flex;
  align-items: center;
}
.img-text-box img:nth-child(1){
  margin-bottom: 10px;
}
.texts-all-4 div{
  display: flex;
  flex-direction: row;
}
.texts-all-4 span{
  display: flex;
  flex-direction: row;
}
.texts-all-4 img{
  height: 16px;
  width: 16px;
  margin-top: 5px;
  margin-right: 5px;
}
.text-type-1{
  font-weight: bold;
  font-size: calc(16px + 1vw);
}
.text-type-2{
  font-size: calc(10px + 0.8vw);
  margin-bottom: 20px;
}
.text-type-3{
  color: gray;
}

.el-carousel__item{
  width: auto;
}
.have-background-img{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
  width: 100%;
  padding-bottom: 30px;
}

.box-contain-2 {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-left: 5%;
}
.box-contain-2 span:nth-child(2){
  margin-top: 10px;
  color: gray;
  line-height: 2;
  width: 90%;
}

.have-background-color{
  background-color: #f9f9f9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.no-background-color{
  padding-top: 50px;
  padding-bottom: 50px;
}
.texts span:nth-child(1){
  justify-content: center;
  font-size: 30px;
  margin-bottom: 20px;
}
.texts span:nth-child(2){
  font-size: 20px;
}

.benchmark-box img:hover{
  transform: scale(1.1);
}

.black-weight{
  font-weight: bold;
  color: #222222
}
.kanban-height{
  height: 360px;
}

@media (max-width: 768px) {
  .texts-all-4 img{
    margin-top: 0;
  }
  .text-type-2{
    font-size: calc(10px + 0.8vw);
    margin-bottom: 10px;
  }
  .change-img-bottom img{
    margin-bottom: 20px;
  }
  .box-contain-2 span:nth-child(2){
    width: 100%;
  }
  .benchmark-box img{
    width: 100%;
  }
  .texts {
    margin-top: calc(3px + 1vw);
  }
  .texts span:nth-child(1){
    justify-content: center;
    margin-bottom: 0;
  }
  .texts span:nth-child(2){
    padding-bottom: 0;
    font-size: 16px;
  }
  .new-color-module{
    padding-top: 0;
  }

  .kanban-height{
    height: 60vw;
  }
}
</style>
