import { createApp } from 'vue';
import appVue from "./App.vue";
// 假设你有一个名为 routes 的数组来定义路由
import router from './router';

// 全局导入组件
import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";
import BottomNavbar from "@/components/BottomNavbar.vue";
import EveryName from "@/components/EveryName.vue";


import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(appVue);


// 挂载应用到 DOM 元素上
app.use(ElementPlus);
app.use(router); // 在这里使用 VueRoute
app.component('NavbarCombination',NavbarCombination)
app.component('RightNavbar',RightNavbar)
app.component('BottomNavbar',BottomNavbar)
app.component('EveryName',EveryName)
app.mount('#app');//这个要写最后
