<template>
  <NavbarCombination active-page="solves"></NavbarCombination>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img src="@/assets/products_images/banner背景.jpg" alt="" style="text-align: center;">
    <div>
      <span>
        仓配一体化方案
      </span>
      <span>
        落地各行业数字化转型升级的战略目标和价值，共创企业服务生态未来
      </span>
    </div>
  </div>

  <div class="container module describe">
    <span>
      方案概述
    </span>
    <span class="middle-text">
      在当今全球化市场中、产品质量安全已成为消费者和企业共同关注的焦点。为了确保产品质量，维护消费者权益，并为问题产品提供可追溯性，一套行之有效的产品质量安全可溯源方案至关重要。易捷信息为企业提供了一个全面的可追溯性系统，通过该系统，企业可以对产品从生产到销售的每个环节进行监控和管理，尤其是质量检测溯源管理模块，有效的提高产品质量和安全性，减少企业生产成本。
    </span>
  </div>

  <div class="have-background-img">
    <div class="container">
      <img src="@/assets/solve_images/仓配一体化.png">
    </div>

  </div>

  <div class="container module-box">
    <div class="module-title-box">
      <span class="module-title">产品功能及亮点</span>
    </div>
    <div class="row">
      <div class="col-md-6 col-12 img-box">
        <img src="@/assets/home_images/module.png">
      </div>
      <div class="col-md-6 col-12 texts-all-box">
        <div class="row ">
          <div v-for="(content,index) in all_describes" :key="index" class="col-12 drop-down-all">
            <div @click="chooseDropdown(index)" class="drop-down-box" style="cursor: pointer;">
              <img src="@/assets/icons/chevron-right.svg" :class="{'down-nav': visible && (currentIndex === index)}" class="click-nav">
              <span class="drop-down-text1">&nbsp;{{ content.name }}</span>
            </div>
            <transition name="fade" >
              <span v-show="visible && (currentIndex===index)" class="drop-down-text2" style="overflow: hidden;height: auto;">{{ content.text }}</span>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};

export default {
  name: "ProductsCenter",
  components: { RightNavbar, NavbarCombination},
  data(){
    return{
      visible: true,
      currentIndex: 10,
      Animate1: false,
      isPhone: false,
      all_describes:[
        {
          name:'供应商、客户协同',
          text:'通过信息共享、资源整合、业务协同等方式，实现供应商、客户协同时信息透明化'
        },
        {
          name:'收货入库',
          text:'全程上架自动化'
        },
        {
          name:'存储管理',
          text:'充分利用仓库存储空间，密度更高、拣货更精准'
        },
        {
          name:'配送上料及入库',
          text:'配送任务下发AGV,实现AGV更优、更快的上料及上下架'
        },
      ],
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
    chooseDropdown(index){
      if (this.currentIndex !== index){
        this.currentIndex = index;
        if (!this.visible){
          this.visible = !this.visible;
        }
      }else {
        this.visible = !this.visible;
      }
    },

  },
}
</script>

<style scoped>
/* 有背景图片 */
.have-background-img{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .show-img div{
    bottom: 30%;
  }
}
</style>