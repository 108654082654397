<template>
  <NavbarCombination :activePage="'products'"/>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img v-if="isPhone" src="@/assets/MobileAPP_images/banner背景-移动端.jpg" alt="">
    <img v-else src="@/assets/MobileAPP_images/banner背景.jpg" alt="">
    <div>
      <span>
        打造口袋中的数字企业
      </span>
      <span>
        无纸化作业,随时随地高效办公
      </span>
    </div>
  </div>
  <div class="container module describe">
    <span>
      易捷移动端应用概述
    </span>
    <span class="middle-text">
      微信小程序，手机APP,PDA,平板及电视机等，将企业管理系统延伸到移动端，实现任何地点、任何时间的连接，并根据移动场景提供有针对性的碎片化应用。与电脑端实时交互协同，使智能手机拥有相同的办公功能，让用户轻松把企业装进手机，随时随地高效工作，将管理范围延伸至任何一个有网络的地方。
    </span>
  </div>

  <div class="container change-img-bottom" :class="Animate1 ? 'logobox myanimation' : 'logobox'" ref="logobox1">
    <div class="container module describe change-the-text">
      <span>部分场景应用</span>
    </div>
    <div v-if="isPhone" class="row apply-3 no-background-color">
      <img src="@/assets/MobileAPP_images/移动下单.png" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          移动下单
        </span>
        <span class="middle-text">
          移动端可随时随填写、提交订单，实时同步电脑端，无需重复操作；<br>
          管理人员可以实时查看、审批订单
        </span>
      </div>
    </div>
    <div v-else class="row apply-3 no-background-color">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          移动下单
        </span>
        <span class="middle-text">
          移动端可随时随填写、提交订单，实时同步电脑端，无需重复操作；<br>
          管理人员可以实时查看、审批订单
        </span>
      </div>
      <img src="@/assets/MobileAPP_images/移动下单.png" class="col-md-6 col-12">
    </div>
  </div>
  <div class="have-background-color change-img-bottom" :class="Animate2 ? 'logobox myanimation' : 'logobox'" ref="logobox2">
    <div class="container">
      <div class="row apply-3">
        <img src="@/assets/MobileAPP_images/高效报工.png" class="col-md-6 col-12">
        <div class="col-md-6 box-contain-2 col-12">
          <span class="text-type-1">
            高效报工
          </span>
          <span class="middle-text">
            支持PDA、APP等高效报工方式，解决不同场地应用问题；<br>
            无障碍链接智能终端，支持PDA、平板、小程序等多端协同操作，高效便捷；<br>
            报工结果直接关联质检结果，不仅省时且准确率明显提高
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="container no-background-color change-img-bottom" :class="Animate3 ? 'logobox myanimation' : 'logobox'" ref="logobox3">
    <div v-if="isPhone" class="row apply-3">
      <img src="@/assets/MobileAPP_images/移动质检.png" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          移动质检
        </span>
        <span class="middle-text">
          自定义多类型质检方案，实时了解现场质检结果，支持走动式质检，结果随时填写拍照上传；<br>
          可远程监控生产质量，快速追溯质量源头
        </span>
      </div>
    </div>
    <div v-else class="row apply-3">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          移动质检
        </span>
        <span class="middle-text">
          自定义多类型质检方案，实时了解现场质检结果，支持走动式质检，结果随时填写拍照上传；<br>
          可远程监控生产质量，快速追溯质量源头
        </span>
      </div>
      <img src="@/assets/MobileAPP_images/移动质检.png" class="col-md-6 col-12">
    </div>
  </div>
  <div class="have-background-color change-img-bottom" :class="Animate4 ? 'logobox myanimation' : 'logobox'" ref="logobox4">
    <div class="container">
      <div class="row apply-3">
        <img src="@/assets/MobileAPP_images/扫码出入库.png" class="col-md-6 col-12">
        <div class="col-md-6 box-contain-2 col-12">
          <span class="text-type-1">
            扫码出入库
          </span>
          <span class="middle-text">
            生产人员选择设备及工位就可上工；<br>
            生产人员只需要通过手机或PDA扫生产工艺卡，即可实现扫码自动报工，将生产工序、人员、设备、产品、数量等关信息自动提交并录入ERP系统
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="container no-background-color change-img-bottom" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
    <div v-if="isPhone" class="row apply-3">
      <img src="@/assets/MobileAPP_images/立体化报修.png" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          立体化报修
        </span>
        <span class="middle-text">
          支持客户自助在线提交售后工单，同时支持电话、网站、微信小程序、维码、APP等报修渠道，与您的客户无缝连接。<br>
          来自不同报修渠道的客户请求，系统生成工单集中统一处理
        </span>
      </div>
    </div>
    <div v-else class="row apply-3">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          立体化报修
        </span>
        <span class="middle-text">
          支持客户自助在线提交售后工单，同时支持电话、网站、微信小程序、维码、APP等报修渠道，与您的客户无缝连接。<br>
          来自不同报修渠道的客户请求，系统生成工单集中统一处理
        </span>
      </div>
      <img src="@/assets/MobileAPP_images/立体化报修.png" class="col-md-6 col-12">
    </div>
  </div>
  <div class="have-background-color change-img-bottom" :class="Animate6 ? 'logobox myanimation' : 'logobox'" ref="logobox6">
    <div class="container">
      <div class="row apply-3">
        <img src="@/assets/MobileAPP_images/数据看板.png" class="col-md-6 col-12">
        <div class="col-md-6 box-contain-2 col-12">
          <span class="text-type-1">
            数据看板
          </span>
          <span class="middle-text">
            车间生产状况轻松掌握，轻松分析产能趋势，延期订单及时预警
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="have-background-img">
    <div class="container describe change-the-text-top">
      <span>案例分享</span>
    </div>
    <div v-if="isPhone" class="container cases-all">
      <div class="row">
        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/美多电器-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate7 ? 'logobox myanimation' : 'logobox'" ref="logobox7">
              <span>美多电器</span>
              <span>"借助易捷移动端应用,使货物出入库更加便捷,减少人为误差,提高企业运作效率"</span>
            </div>
          </div>
        </div>
        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/健达通车用电子-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate7 ? 'logobox myanimation' : 'logobox'" ref="logobox7">
              <span>健达通车用电子</span>
              <span>"通过实施易捷ERP系统，构建进销存一体化，帮助企业提升管理效率;精确的仓库管理体系，优化资源配置"</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container cases-all">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/美多电器.jpg" alt="erp">
            <div class="texts" :class="Animate7 ? 'logobox myanimation' : 'logobox'" ref="logobox7">
              <span>美多电器</span>
              <span >"通过实施易捷MES系统，实现生产全过程管控；通过派工和PDA移动扫码报工，极大提升现场作业进度采集效率"</span>
            </div>
          </div>
        </div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/健达通车用电子.jpg" alt="erp">
            <div class="texts" :class="Animate7 ? 'logobox myanimation' : 'logobox'" ref="logobox7">
              <span>健达通车用电子</span>
              <span >"借助易捷移动端应用,实现走动式质检,实时记录物料/工序检验问题,有效严控产品质量"</span>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </div>

</template>

<script>

import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};

export default {
  name: "ERPPlan",
  components: {RightNavbar, NavbarCombination},
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      Animate5: false,
      Animate6: false,
      Animate7: false,
      isPhone: false,
      currentIndex: 0, // 当前轮播图的索引
      activeIndex: 0,
      names:[
        '一体化管理','一物一码','多系统可互联','支持各式移动装备'
      ],
      images: [{
        url: require('@/assets/ERP_images/一体化管理.jpg'),
        text1:'信息共通共享',
        text2:'电子化的企业管理模式，高效管理协同，降低人力管理成本',
        text3:['实现不同管理模块间的数据整合和信息重组，业务信息统一管理，降低部门间信息共享的风险性，提高了工作与交付效率；',
            '跨部门跨区域之间进行实时的信息流及决策管理，提升多部门协同合作的效率；',
            '一个集多个功能模块于一体的信息化管理平台']
      },
        {
          url: require('@/assets/ERP_images/一物一码.jpg'),
          text1:'物联网时代万物互联的抓手',
          text2:'实时监控产品动态，把控产品全周期动态，对产品的追踪溯源',
          text3:['实现从成品到半成品到成品（物料）的可监控、可追溯，确保产品生产过程的质量控制；',
            '对产品进行全生命周期的追溯和数据管理，保障产品源头的可塑性；',
            '通过实时追溯批次、品质等原料信息，提升产品质量和生产效率']
        }, {
          url: require('@/assets/ERP_images/多系统可互联.jpg'),
          text1:'一键部署数据互通',
          text2:'数据统一汇聚，统一存储，集中计算，集中管控和统一共享',
          text3:['提供一站式系统解决方案，多系统支持原生互联，一部署数据互通；',
            '每个系统又可独立运行，预留开放接口，迅速与第三方系统对接；']
        },
        {
          url: require('@/assets/ERP_images/支持各式移动装置.jpg'),
          text1:'多端协同，实时通同步',
          text2:'无障碍连接多端智能终端，实时同步数据',
          text3:['多平台无缝协作，不受场地限制；',
            '每个系统又可独立运行，预留开放接口，迅速与第三方系统对接；']
        },

      ]
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener('scroll',this.scrollToTop);
    next();
  },
  methods:{
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
      this.Animate2=!isElementNotInViewport(this.$refs.logobox2)
      this.Animate3=!isElementNotInViewport(this.$refs.logobox3)
      this.Animate4=!isElementNotInViewport(this.$refs.logobox4)
      this.Animate5=!isElementNotInViewport(this.$refs.logobox5)
      this.Animate6=!isElementNotInViewport(this.$refs.logobox6)
      this.Animate7=!isElementNotInViewport(this.$refs.logobox7)
    },
    handleCarouselChange(currentIndex) {
      this.currentIndex = currentIndex;
    },
    choosePage(index) {
      if (index >= 0 && index < 4) { // 确保索引在有效范围内
        this.$refs.carousel.setActiveItem(index)
      }
      console.log(this.activeIndex)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
  }
}
</script>

<style scoped>
.describe{
  display: flex;
  flex-direction: column;
  width: 90%;
}
.describe span:nth-child(1){
  font-size: calc(20px + 1.5vw);
  text-align: center;
  margin-bottom: 20px;
  padding-top: 20px;
}
.change-the-text{
  margin-bottom: 20px;
}
.change-the-text-top{
  padding-top: 30px;
}
.custom-indicators{
  text-align: center;
  display: flex;
}
.custom-indicators div{
  font-size: calc(8px + 1vw);
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.custom-indicators * {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}
.custom-indicators .active{
  color: cornflowerblue!important;
}
.custom-indicators .active::after {
  content: ""; /* 伪元素必须有内容 */
  position: absolute; /* 相对于a元素定位 */
  left: 0; /* 从左边开始 */
  right: 0; /* 到右边结束 */
  bottom: -10px; /* 下划线与文字之间的间隔 */
  height: 4px; /* 下划线的厚度 */
  background-color: cornflowerblue; /* 下划线的颜色 */
  opacity: 1!important;
  transition: width 10s ease; /* 过渡效果 */
}

.img-text-box{
  display: flex;
  align-items: center;
}
.img-text-box img:nth-child(1){
  margin-bottom: 10px;
}
.texts-all-4 div{
  display: flex;
  flex-direction: row;
}
.texts-all-4 span{
  display: flex;
  flex-direction: row;
}
.texts-all-4 img{
  height: 16px;
  width: 16px;
  margin-top: 5px;
  margin-right: 5px;
}
.text-type-1{
  font-weight: bold;
  font-size: calc(16px + 1vw);
}

.have-background-img{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
  width: 100%;
  padding-bottom: 30px;
}

.box-contain-2 {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-left: 5%;
}
.box-contain-2 span:nth-child(2){
  margin-top: 10px;
  color: gray;
  line-height: 2;
  width: 90%;
}

.have-background-color{
  background-color: #f9f9f9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.no-background-color{
  padding-top: 50px;
  padding-bottom: 50px;
}
.texts span:nth-child(1){
  justify-content: center;
  font-size: 30px;
  margin-bottom: 20px;
}
.texts span:nth-child(2){
  font-size: 20px;
}

.benchmark-box img:hover{
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .texts-all-4 img{
    margin-top: 0;
  }
  .text-type-2{
    font-size: calc(10px + 0.8vw);
    margin-bottom: 10px;
  }
  .change-img-bottom img{
    margin-bottom: 20px;
  }
  .box-contain-2 span:nth-child(2){
    width: 100%;
  }
  .benchmark-box img{
    width: 100%;
  }
  .texts {
    margin-top: calc(3px + 1vw);
  }
  .texts span:nth-child(1){
    justify-content: center;
    margin-bottom: 0;
  }
  .texts span:nth-child(2){
    padding-bottom: 0;
    font-size: 16px;
  }
  .show-img div span:nth-child(1){
    font-size: 31px;
  }
}
</style>