<template>
  <NavbarCombination :activePage="'products'"/>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img src="@/assets/ASMS_imgaes/banner背景.jpg" alt="">
    <div>
      <span>
        一体化售后管理系统
      </span>
      <span>
        流程化运作，构建派单、保修、退换货一体化售后管理平台
      </span>
    </div>
  </div>
  <div class="container module describe">
    <span>
      易捷ASMS系统概述
    </span>
    <span class="middle-text">
      易捷售后管理平台ASMS系统是一套完整的客户服务系统，旨在提供高效、方便、快捷的售后服务。该平台还支持实时通讯和协作功能，让客户和维修人员可以随时随地保持联系，快速解决问题。
    </span>
  </div>
  <div class="new-color-module">
    <div class="container describe change-the-text">
      <span>无死角覆盖售后全场景</span>
    </div>
    <div>
      <!-- 自定义文字指示器 -->
      <div class="custom-indicators container">
        <div v-for="(name, index) in names" :key="index" class="container">
          <span class="navbar-4-box row">
            <div class="col">
              <div :class="{ active: currentIndex === index }" @mouseenter="choosePage(index)" @click="choosePage(index)">
                {{ name }}
              </div>
            </div>
          </span>
        </div>
      </div>
      <el-carousel :height="isPhone ? '500px':'550px'" class="container" indicator-position="none" @change="handleCarouselChange" ref="carousel">
        <el-carousel-item v-for="(img, index) in images" :key="index" class="img-text-box row">
          <img :src="img.url" alt="" class="col-md-6 col-12"/>
          <div class="texts-all-4 col-md-6 col-12">
            <span class="text-type-1">{{ img.text1 }}</span>
            <span class="text-type-2">{{ img.text2 }}</span>
            <div v-for="(text, index) in img.text3" :key="index">
              <img src="@/assets/ERP_images/图标-1.png">
              <span class="text-type-3 small-text">
                {{ text }}
              </span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
  <div class="new-color-module bc-color-change">
    <div class="container describe change-the-text">
      <span>售后服务行业现状</span>
    </div>
    <div class="container change-box-bottom">
      <div class="row">
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            01
          </span>
          <span class="largest-text">
            客户保修渠道单一
          </span>
          <span class="middle-text">
            传统报修渠道单一，各类咨询和问题响应涑度较慢，服务和管理效率低、口碑难建立
          </span>
        </div>
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            02
          </span>
          <span class="largest-text">
            服务进度难以追踪
          </span>
          <span class="middle-text">
            服务进度封闭，客户服务内容无工单记录无法追溯，影响核心业务发展，不能及时掌握服务进度，效率难保障，服务响应慢
          </span>
        </div>
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            03
          </span>
          <span class="largest-text">
            人员管理及服务质量难监管
          </span>
          <span class="middle-text">
            人员考核没数据，各个环节割裂，现场服务流程和步骤不规范，服务质量监管难度大
          </span>
        </div>
      </div>
      <div class="row">
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            04
          </span>
          <span class="largest-text">
            配件管理及配件使用不规范
          </span>
          <span class="middle-text">
            配件物料申领出库、使用、归还路径难以追踪，库存盘点不准确，个人申领及使用缺乏记录不规范，造成企业资产流失
          </span>
        </div>
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            05
          </span>
          <span class="largest-text">
            数据价值埋没，服务决策难
          </span>
          <span class="middle-text">
            设备全生命周期数据收集不健全、现场服务数据难使用，无法与ERP、CRM等系统数据整合，人工处理数据耗时久，效率低，难以及时发现问题。
          </span>
        </div>
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            06
          </span>
          <span class="largest-text">
            全国服务交付用工成本高
          </span>
          <span class="middle-text">
            内部员工异地派工差旅工时成本高、服务不及时，临时用工质量难保障、风险高、结算合规性差，加重服务交付整体成本。
          </span>
        </div>
      </div>
    </div>

  </div>

  <div class="container change-img-bottom" :class="Animate1 ? 'logobox myanimation' : 'logobox'" ref="logobox1">
    <div class="container module describe change-the-text">
      <span>解决方案</span>
    </div>
    <div v-if="isPhone" class="row apply-3 no-background-color">
      <img src="@/assets/ASMS_imgaes/立体化报修渠道.png" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          立体化报修渠道
        </span>
        <span class="middle-text">
          支持客户自主扫码在线提交售后工单，同时支持电话、网站、微信小程序、二维码、APP等报修渠道，与您的客户无缝连接。来自不同报修渠道的客户请求，系统生成工单集中统一处理
        </span>
      </div>
    </div>
    <div v-else class="row apply-3 no-background-color">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          立体化报修渠道
        </span>
        <span class="middle-text">
          支持客户自主扫码在线提交售后工单，同时支持电话、网站、微信小程序、二维码、APP等报修渠道，与您的客户无缝连接。来自不同报修渠道的客户请求，系统生成工单集中统一处理
        </span>
      </div>
      <img src="@/assets/ASMS_imgaes/立体化报修渠道.png" class="col-md-6 col-12">
    </div>
  </div>
  <div class="have-background-color change-img-bottom" :class="Animate2 ? 'logobox myanimation' : 'logobox'" ref="logobox2">
    <div class="container">
      <div class="row apply-3">
        <img src="@/assets/ASMS_imgaes/实时跟进服务进度.png" class="col-md-6 col-12">
        <div class="col-md-6 box-contain-2 col-12">
          <span class="text-type-1">
            实时跟进服务进度
          </span>
          <span class="middle-text">
            实现现场服务管理并高效处理现场任务，智能化调度，根据地理位置、产品信息等将资源合理化分配，生成最优安装维修方案。并且实时查看服务进度，高效管理现场作业流程
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="container no-background-color change-img-bottom" :class="Animate3 ? 'logobox myanimation' : 'logobox'" ref="logobox3">
    <div v-if="isPhone" class="row apply-3">
      <img src="@/assets/ASMS_imgaes/可视化服务监管.png" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          可视化服务监管
        </span>
        <span class="middle-text">
          可扫码登录售后管理平台将产品故障现象描述并且上传图片，并提交预计上门时间，售后人员确定上门时间后，系统即时自动向客户发送短信通知，提醒顾客预约成功
        </span>
      </div>
    </div>
    <div v-else class="row apply-3">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          可视化服务监管
        </span>
        <span class="middle-text">
          可扫码登录售后管理平台将产品故障现象描述并且上传图片，并提交预计上门时间，售后人员确定上门时间后，系统即时自动向客户发送短信通知，提醒顾客预约成功
        </span>
      </div>
      <img src="@/assets/ASMS_imgaes/可视化服务监管.png" class="col-md-6 col-12">
    </div>
  </div>
  <div class="have-background-color change-img-bottom" :class="Animate4 ? 'logobox myanimation' : 'logobox'" ref="logobox4">
    <div class="container">
      <div class="row apply-3">
        <img src="@/assets/ASMS_imgaes/精细化配件管理.png" class="col-md-6 col-12">
        <div class="col-md-6 box-contain-2 col-12">
          <span class="text-type-1">
            精细化配件管理
          </span>
          <span class="middle-text">
            售后配件线上申请，保证服务的及时生追溯配件到人和单。根据售后维修人员、服务商申请售后配件单，售后服务平台与ERP、MES系统数据互通，实现统一调拨、收发货等流程管理
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="have-background-img">
    <div class="container describe change-the-text-top">
      <span>案例分享</span>
    </div>
    <div v-if="isPhone" class="container cases-all">
      <div class="row">
        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/美多电器-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate6 ? 'logobox myanimation' : 'logobox'" ref="logobox6">
              <span>美多电器</span>
              <span>"通过易捷售后系统，实现客户保修渠道多样，与客户无缝连接，系统生成工单集中统一管理"</span>
            </div>
          </div>
        </div>
        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/ASMS_imgaes/卡梦帝电器-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate6 ? 'logobox myanimation' : 'logobox'" ref="logobox6">
              <span>卡梦帝电器</span>
              <span>"借助易捷售后系统，实现与上层系统数据互通，实现统一调拨、收发货等流程管理"</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container cases-all">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/美多电器.jpg" alt="erp">
            <div class="texts" :class="Animate6 ? 'logobox myanimation' : 'logobox'" ref="logobox6">
              <span>美多电器</span>
              <span >"通过易捷售后系统，实现客户保修渠道多样，与客户无缝连接，系统生成工单集中统一管理"</span>
            </div>
          </div>
        </div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/ASMS_imgaes/卡梦帝电器.jpg" alt="erp">
            <div class="texts" :class="Animate6 ? 'logobox myanimation' : 'logobox'" ref="logobox6">
              <span>卡梦帝电器</span>
              <span >"借助易捷售后系统，实现与上层系统数据互通，实现统一调拨、收发货等流程管理"</span>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </div>

</template>

<script>

import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};

export default {
  name: "ERPPlan",
  components: {RightNavbar, NavbarCombination},
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      Animate5: false,
      Animate6: false,
      isPhone: false,
      currentIndex: 0, // 当前轮播图的索引
      activeIndex: 0,
      names:[
        '智能化工单分配','多端适配','工单状态管理','客户自助服务'
      ],
      images: [{
        url: require('@/assets/ASMS_imgaes/智能化工单分配.jpg'),
        text1:'多场景多维度派工',
        text2:'根据业务场景需求不同，灵活选择业务流程',
        text3:['可指派特定人员处理任务；',
            '支持多级派工，先指派区域售后点，再由售后点指派下级处理任务',]
      },
        {
          url: require('@/assets/ASMS_imgaes/多端适配.jpg'),
          text1:'多端适配',
          text2:'无障碍链接多智能终端，支持电脑、手机等高效协作方式',
          text3:['按需启用不同售后人员的移动端，可实现手机、电脑等处理售后问题',
            '手机端查看售后工单、任务等，轻松解决不同场地应用问题；',
            '售后进度、结果多终端实时同步，过程省时高效']
        }, {
          url: require('@/assets/ASMS_imgaes/工单状态管理.jpg'),
          text1:'工单状态管理',
          text2:'工单状态一目了然，实时监控工单处理情况',
          text3:['各环节处理完毕，售后状态同步更新',
            '维修人员可以实时更新工单处理状态，让客户清楚地了解工单进展情况；']
        },
        {
          url: require('@/assets/ASMS_imgaes/客户自助服务.jpg'),
          text1:'客户自助服务',
          text2:'简单输入，精确输出',
          text3:['简化客户保修流程，提高保修效率；',
            '支持客户自主服务系统，如故障保修、进度查询、评价反馈等功能，让客户可以自主完成保修和查询操作，减少人工干预和等待时间']
        },

      ]
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener('scroll',this.scrollToTop);
    next();
  },
  methods:{
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
      this.Animate2=!isElementNotInViewport(this.$refs.logobox2)
      this.Animate3=!isElementNotInViewport(this.$refs.logobox3)
      this.Animate4=!isElementNotInViewport(this.$refs.logobox4)
      this.Animate5=!isElementNotInViewport(this.$refs.logobox5)
      this.Animate6=!isElementNotInViewport(this.$refs.logobox6)
    },
    handleCarouselChange(currentIndex) {
      this.currentIndex = currentIndex;
    },
    choosePage(index) {
      if (index >= 0 && index < 4) { // 确保索引在有效范围内
        this.$refs.carousel.setActiveItem(index)
      }
      console.log(this.activeIndex)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
  }
}
</script>

<style scoped>
.describe{
  display: flex;
  flex-direction: column;
  width: 90%;
}
.describe span:nth-child(1){
  font-size: calc(20px + 1.5vw);
  text-align: center;
  margin-bottom: 20px;
  padding-top: 20px;
}
.change-the-text{
  margin-bottom: 20px;
}
.change-the-text-top{
  padding-top: 30px;
}
.custom-indicators{
  text-align: center;
  display: flex;
}
.custom-indicators div{
  font-size: calc(8px + 1vw);
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.custom-indicators * {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}
.custom-indicators .active{
  color: cornflowerblue!important;
}
.custom-indicators .active::after {
  content: ""; /* 伪元素必须有内容 */
  position: absolute; /* 相对于a元素定位 */
  left: 0; /* 从左边开始 */
  right: 0; /* 到右边结束 */
  bottom: -10px; /* 下划线与文字之间的间隔 */
  height: 4px; /* 下划线的厚度 */
  background-color: cornflowerblue; /* 下划线的颜色 */
  opacity: 1!important;
  transition: width 10s ease; /* 过渡效果 */
}

.img-text-box{
  display: flex;
  align-items: center;
}
.img-text-box img:nth-child(1){
  margin-bottom: 10px;
}
.texts-all-4 div{
  display: flex;
  flex-direction: row;
}
.texts-all-4 span{
  display: flex;
  flex-direction: row;
}
.texts-all-4 img{
  height: 16px;
  width: 16px;
  margin-top: 5px;
  margin-right: 5px;
}
.text-type-1{
  font-weight: bold;
  font-size: calc(16px + 1vw);
}
.text-type-2{
  font-size: calc(10px + 0.8vw);
  margin-bottom: 20px;
}
.text-type-3{
  color: gray;
}

.el-carousel__item{
  width: auto;
}
.have-background-img{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
  width: 100%;
  padding-bottom: 30px;
}

.box-contain-2 {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-left: 5%;
}
.box-contain-2 span:nth-child(2){
  margin-top: 10px;
  color: gray;
  line-height: 2;
  width: 90%;
}

.have-background-color{
  background-color: #f9f9f9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.no-background-color{
  padding-top: 50px;
  padding-bottom: 50px;
}
.texts span:nth-child(1){
  justify-content: center;
  font-size: 30px;
  margin-bottom: 20px;
}
.texts span:nth-child(2){
  font-size: 20px;
}

.benchmark-box img:hover{
  transform: scale(1.1);
}
.bc-color-change{
  background-color: #f9f9f9;
}
.blocks9{
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-left: 8px;
  height: 250px;
  box-shadow: rgba(211,211,211,0.5) 0 0 16px;
  margin-bottom: 16px;
}
.blocks9>span:nth-child(1){
  margin-top: 12%;
  font-weight: bold;
}
.blocks9>span:nth-child(2){
  font-weight: bold;
  margin-bottom: 3%;
}
.change-box-bottom{
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .show-img div{
    bottom: 30%;
  }
  .texts-all-4 img{
    margin-top: 0;
  }
  .text-type-2{
    font-size: calc(10px + 0.8vw);
    margin-bottom: 10px;
  }
  .change-img-bottom img{
    margin-bottom: 20px;
  }
  .box-contain-2 span:nth-child(2){
    width: 100%;
  }
  .benchmark-box img{
    width: 100%;
  }
  .texts {
    margin-top: calc(3px + 1vw);
  }
  .texts span:nth-child(1){
    justify-content: center;
    margin-bottom: 0;
  }
  .texts span:nth-child(2){
    padding-bottom: 0;
    font-size: 16px;
  }
  .blocks9{
    height: auto;
    margin:5px 5% 10px 5%;
    width: 90%;
    box-shadow: rgba(211,211,211,0.5) 0 0 20px;
  }
  .blocks9>span:nth-child(1){
    margin-top: 8%;
    margin-bottom: 0;
  }
  .blocks9>span:nth-child(2){
    margin-bottom: 10px;
  }
  .blocks9>span:nth-child(3){
    margin-bottom: 10px;
  }
}
</style>