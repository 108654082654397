<template>
  <div v-if="isPhone" class="background-img three-all">
    <div class="first">
      <img src="@/assets/home_images/易捷信息logo.png">
      <div class="text_two">
        <span class="mini-text">
          公司总机：
        </span>
        <span>
          0575-81383566
        </span>
      </div>
      <div class="text_two">
        <span class="mini-text">
          邮箱：
        </span>
        <span>
          he@estoon.com
        </span>
      </div>
      <div class="text_two">
        <span class="mini-text">
          地址：
        </span>
        <span>
          浙江省嵊州市浦南大道科技创业中心1004
        </span>
      </div>
    </div>
    <div class="second">
      <span class="mini-text">
        长 按 识 别 下 方 二 维 码
      </span>
      <img src="@/assets/home_images/二维码.jpg">
      <span class="mini-text">
        浙ICP备18032321号
        <img src="@/assets/home_images/易捷信息logo-白色.png">
        版权所有©绍兴易捷信息技术有限公司
      </span>
    </div>
  </div>

  <div v-else class="background-img">
    <div class="container">
      <div class="row">
        <div class="col-7 content-text1a2">
          <div class="content-text1">
            <ul>
              <li>
                首页
              </li>
              <div class="vertical-line"></div>
              <li>
                解决方案
              </li>
              <div class="vertical-line"></div>
              <li>
                关于我们
              </li>
            </ul>
          </div>
          <div class="level-line"></div>
          <div class="content-text2">
            <div>
              <span>
              版权所有©绍兴易捷信息技术有限公司
                <img src="@/assets/home_images/易捷信息logo.png">
              浙ICP备18032321号
              </span>
            </div>
            <div>
              <span>
              公司总机：
              <span class="blue-text">0575-81383566</span>
              邮箱：
              <span class="blue-text">he@estoon.com</span>
              地址：
              <span class="blue-text">浙江省嵊州市浦南大道科技创业中心1004</span>
              </span>
            </div>

          </div>

        </div>
        <div class="col-3 content-text3">
          <span>
            0575-81383566
          </span>
          <span>
            咨询热线
          </span>
        </div>
        <div class="col-2">
          <div class="qrcode">
            <img src="@/assets/home_images/二维码.jpg">
            <span>
            扫一扫关注
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomNavbar",
  data(){
    return{
      screenWidthSize: window.innerWidth,
      isPhone: false,
    }
  },
  methods:{
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
  },

  mounted() {
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeUnmount() {
    // 移除事件监听器
    window.removeEventListener('resize', this.updateScreenSize);
  },
}
</script>

<style scoped>
.background-img{
  background-image: url("@/assets/home_images/优势和底部的背景图.jpg");
  height: auto;
  width: 100vw;
  padding-top: 20px;
  position: relative;
  padding-bottom: 20px;
}
.content-text1{
  display: flex;
  flex-direction: row;
  color: white;
  margin-bottom: 20px;
}
.content-text1 ul{
  display: flex;
  padding-left: 0;
  padding-top: 10px;
}
.content-text1 ul li{
  display: flex;
  margin-right: 20px;
}
.content-text1 ul li:nth-child(3),.content-text1 ul li:nth-child(5){
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
}
.vertical-line{
  height: 0.5vw;
  width: 1px; /* 竖线的宽度 */
  background-color: white; /* 竖线的颜色 */
  margin: auto; /* 在某些情况下，可能需要这个来确保居中 */
}
.level-line{
  width: 520px;
  height: 1px; /* 竖线的宽度 */
  background-color: white; /* 竖线的颜色 */
  margin-bottom: 20px;
}
.blue-text{
  color: dodgerblue;
  font-weight: bold;
}
.content-text2{
  margin-top: 0;
  display: flex;
  flex-direction: column;
  color: white;
}

.content-text3{
  color: white;
  display: flex;
  flex-direction: column;
}
.content-text3 span:nth-child(1){
  font-size: 38px;
  font-weight: bold;
}
.content-text3 span:nth-child(2){
  font-size: 18px;
}
.qrcode{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qrcode img{
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin-top: 10px;
}
.qrcode span{
  padding-top:10px;
  font-size: 14px;
  color: white;
}
.content-text1a2{
  font-size: 15px;
}

.three-all{
  color: white;
}
.first{
  padding-left: 5%;
}
.first img{
  margin-top: 10px;
  margin-bottom: 10px;
}
.first div{
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.second{
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.second img{
  margin-top: 10px;
  margin-bottom: 16px;
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.second span img{
  width: auto;
  height: 18px;
}
.mini-text{
  font-size: 11px;
}
</style>
