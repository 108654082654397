<template>
  <div id="app">
    <router-view/> <!-- 这里是路由对应的组件将被渲染的地方 -->
    <BottomNavbar></BottomNavbar>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>
<style scoped>
@import '@/assets/css/common.css';
</style>

