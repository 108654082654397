<template>
  <NavbarCombination active-page="solves"></NavbarCombination>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img src="@/assets/products_images/banner背景.jpg" alt="" style="text-align: center;">
    <div>
      <span>
        产仓一体化方案
      </span>
      <span>
        落地各行业数字化转型升级的战略目标和价值，共创企业服务生态未来
      </span>
    </div>
  </div>
  <div class="container module describe">
    <span>
      方案概述
    </span>
    <span class="middle-text">
      随着现代制造行业的不断发展，企业已经逐渐从劳动密集型转向技术密集型，大量的自动化设备和智能机器人得到了广泛的应用，工厂网络化、智能化改造需求迫切。易捷软件为制造行业自主设计了现代智能产仓一体化方案，由智能仓储管理软件以及智能硬件构成，帮助企业实现物料仓库、半成品仓库、成品仓库等出入库等业务流程。支持多仓库，精细化管理、信息可追溯，策略配置灵活，可覆盖多种场景。先进的技术框架可实现快速可靠的二次开发，向上可对接多种系统如ERP，向下与WCS配合，也可集成多种硬件设备。实现企业智能产仓一体化。
    </span>
  </div>

  <div class="have-background-img">
    <div class="container" style="display:grid;justify-items: center;">
      <video src="@/assets/solve_images/产仓一体化视频 -最新.mp4" style="max-width: 80%;" controls></video>
    </div>

  </div>

  <div class="container module-box">
    <div class="module-title-box">
      <span class="module-title">产品功能及亮点</span>
    </div>
    <div class="row">
      <div class="col-md-6 col-12 img-box">
        <img src="@/assets/solve_images/产仓一体化方案亮点.jpg">
      </div>
      <div class="col-md-6 col-12 texts-all-box">
        <div class="row ">
          <div v-for="(content,index) in all_describes" :key="index" class="col-12 drop-down-all">
            <div @click="chooseDropdown(index)" class="drop-down-box" style="cursor: pointer;">
              <img src="@/assets/icons/chevron-right.svg" :class="{'down-nav': visible && (currentIndex === index)}" class="click-nav">
              <span class="drop-down-text1">&nbsp;{{ content.name }}</span>
            </div>
            <transition name="fade" >
              <span v-if="visible && (currentIndex===index)" class="drop-down-text2" style="overflow: hidden;height: auto;">{{ content.text }}</span>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};

export default {
  name: "ProductsCenter",
  components: { RightNavbar, NavbarCombination},
  data(){
    return{
      visible: true,
      currentIndex: 10,
      Animate1: false,
      isPhone: false,
      all_describes:[
        {
          name:'柔性部署',
          text:'全场景全场景产仓一体化规划，基于多种导航技术实现灵活部署，适应业务变化'
        },
        {
          name:'自动化生产',
          text:'多机型/设备差异化协同，实现全链路物流自动化；物料自动对接产线工位，实现机器换人作业'
        },
        {
          name:'信息化管理',
          text:'系统无缝对接，生产信息全程可追溯，消除信息孤岛'
        },
        {
          name:'数智化升级',
          text:'仓储管理系统防呆防错，实现信息化、智能化；数据分析辅助决策，助力精益生产'
        },
      ],
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
    chooseDropdown(index){
      if (this.currentIndex !== index){
        this.currentIndex = index;
        if (!this.visible){
          this.visible = !this.visible;
        }
      }else {
        this.visible = !this.visible;
      }
    },

  },
}
</script>

<style scoped>
/* 有背景图片 */
.have-background-img{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.show-detail{
  position: relative;
  overflow: hidden;
}
@media (max-width: 768px) {
  .show-img div{
    bottom: 30%;
  }
}
</style>