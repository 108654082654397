<template>
  <NavbarCombination :activePage="'aboutUs'"/>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img v-if="isPhone" src="@/assets/about_images/banner背景-移动端.jpg" alt="">
    <img v-else src="@/assets/about_images/banner背景.jpg" alt="">
    <div style="color: dodgerblue;">
      <span>
        助力企业，共创未来
      </span>
      <span>
        落地各行业数字化转型升级的战略目标和价值，共创企业服务生态未来
      </span>
    </div>
  </div>
  <div class="have-background-color" style="padding-bottom: 50px">
    <div class="container">
      <div class="module-title-box">
        <span class="module-title">关于易捷</span>
      </div>
      <div class="row introduce-all-box">
        <div class="col-md-6 col-12">
          <img src="@/assets/about_images/门头.jpg">
        </div>
        <div class="col-md-6 col-12 introduce-text-box">
          <span class="large-text">绍兴易捷信息技术有限公司</span>
          <p>
            易捷信息的初心，是致力于打造一套完整的企业数字化、智能化建设产品矩阵，因为我们深刻的认识到企业从资源管理到车间管理再到仓储管理就是一个整体，想要发挥企业整体管理的价值，就不能出现信息孤岛，要在企业内部管理实现信息和资源的互通，形成自上而下完美的管理闭环，以求最终达到企业利润最大化的目的。
          </p>
          <p>
            易捷信息以一体化为顶层设计理念，全面布局产品生态，形成“一体ERP”和“智能制造执行线”两大产品矩阵，为企业提供全方位的数智化转型解决方案。通过经典ERP资源管理系统、MES车间执行系统、WES仓库执行系统以及SAAS平台等丰富产线，提供覆盖各个业务领域的功能模块和实时信息，数据互通，随需组合，多维决策，持续降本提效增质，让一体化管理赋能企业发展每一步。同时深入多个行业，打造行业专属解决方案，满足不同行业、不同企业、不同规模、不同阶段等复杂多变的需求。
          </p>
        </div>
      </div>
    </div>
  </div>
  <div style="padding-bottom: 100px;">
    <div class="largest-text"
        style="position: absolute;z-index: 999;text-align: center;width: 100%;color: white;text-shadow:1px 1px 10px rgba(0, 0, 0, 0.8);padding-top: 100px">
      打造领航未来的智慧引擎, 助力企业乘风破浪
    </div>
    <div class="content-banner">

    </div>
    <div class="container" style="margin-top: -150px">
      <div class="row">
        <div class="col-md-3 col-12">
          <div class="our-good-job-box">
            <p style="font-weight: bold">
              自主研发，技术引领创新
            </p>
            拥有一支精英研发团队，专注于前沿技术的探索与应用，自主开发的软件解决方案不仅贴近市场需求，更蕴含创新基因，确保技术领先，持续为企业注入活力。
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="our-good-job-box">
            <p style="font-weight: bold">
              定制化软件，精准适配
            </p>
            真正做到“以客户为中心”，提供一对一深度定制服务。无论是特定业务流程优化，还是行业特性的深度融入，您都能量身打造最适合的软件系统，实现管理与业务的无缝对接。
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="our-good-job-box">
            <p style="font-weight: bold">
              线下实操，全程护航
            </p>
            一对一线下实施指导，确保软件落地的每一步都稳健前行。从系统部署到员工培训，您的专业团队现场支持，解决实施过程中的一切难题，加速企业转型升级的步伐。
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="our-good-job-box">
            <p style="font-weight: bold">
              互联互通，构建智慧生态
            </p>
            各系统间流畅的数据交换与协同作业，打破信息孤岛，构建起高度整合的数字神经系统。这不仅提升了工作效率，更开启了数据驱动决策的新篇章，让企业运作更加智能、高效。
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="have-background-color">
    <div class="container">
      <div class="module-title-box2">
        <span class="module-title">企业文化</span>
      </div>
      <div class="row origin-all-box">
        <div class="col-md-6 col-12 origin-text-box">
          <div class="origin-year-box">
            <span>2018&nbsp;</span>
            <span>始于</span>
          </div>
          <div class="idea-text-box">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="dodgerblue" class="bi bi-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              </svg>
              <span class="large-text">&nbsp;经营理念：</span>
              <span class="large-text">提升价值，成就客户</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="dodgerblue" class="bi bi-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              </svg>
              <span class="large-text">&nbsp;产品理念：</span>
              <span class="large-text">笃行拓新，敦行致远</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="dodgerblue" class="bi bi-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              </svg>
              <span class="large-text">&nbsp;品质理念：</span>
              <span class="large-text">质量是信誉的基石</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="dodgerblue" class="bi bi-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              </svg>
              <span class="large-text">&nbsp;服务理念：</span>
              <span class="large-text">用户至上，服务用心</span>
            </div>
          </div>

        </div>
        <div class="col-md-6 col-12">
          <video src="@/assets/about_images/宣传片.mp4" style="max-width: 100%;" controls></video>
        </div>
      </div>
    </div>
  </div>
  <div class="have-background-img">
    <div class="container">
      <div class="module-title-box2">
        <span class="module-title">相关荣誉</span>
      </div>
    </div>
    <div v-if="isPhone" class="container">
      <el-carousel height="300px;" class="carousel-6-box" arrow="always">
        <el-carousel-item style="text-align: center">
          <img src="@/assets/about_images/高新技术企业.jpg">
        </el-carousel-item>
        <el-carousel-item style="text-align: center">
          <img src="@/assets/about_images/绍兴市智能制造产业协会会员单位.jpg">
        </el-carousel-item>
        <el-carousel-item style="text-align: center">
          <img src="@/assets/about_images/嵊州市数字经济促进会理事单位.jpg" style="width: 210px">
        </el-carousel-item>
        <el-carousel-item style="text-align: center">
          <img src="@/assets/about_images/绍兴市级工业互联网平台1.png" style="width: 200px">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else>
      <div class="honor-box container" style="display: flex; flex-direction: row">
          <img src="@/assets/about_images/高新技术企业.jpg" style="flex: 3" class="honor-item">
          <img src="@/assets/about_images/绍兴市智能制造产业协会会员单位.jpg" style="flex: 3"  class="honor-item">
          <img src="@/assets/about_images/嵊州市数字经济促进会理事单位.jpg" style="flex: 2"  class="honor-item">
          <img src="@/assets/about_images/绍兴市级工业互联网平台1.png"  style="flex: 2"  class="honor-item">
      </div>
      <div class="row bottom-white-box"></div>
    </div>
    <div class="container">
      <div class="module-title-box2">
        <span class="module-title">相关资质认证</span>
      </div>
    </div>
    <div v-if="isPhone" class="container">
      <el-carousel height="60vw" class="carousel-6-box" arrow="always" indicator-position="none">
        <div v-for="(img, index) in works" :key="index">
          <el-carousel-item  v-if="index%2===0 && index!==0">
            <div>
              <img :src="works[index-2].url" alt="" style="display: inline-block;width: 50%;padding: 0 2%"/>
              <img :src="works[index-1].url" alt="" style="display: inline-block;width: 50%;padding: 0 2%"/>
            </div>
          </el-carousel-item>
        </div>
      </el-carousel>
    </div>
    <div v-else class="container windows">
      <div id="images" class="images">
        <ul v-for="(image,index) in works" :key="index">
          <li>
            <img :src="image.url">
          </li>
        </ul>
      </div>
    </div>
    <div v-show="!isPhone" class="row bottom-white-box"></div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data(){
    return {
      screenWidthSize: window.innerWidth,
      isPhone: false,
      works:[
        {
          url: require('@/assets/about_images/ERP系统著作权.jpg'),
        },
        {
          url: require('@/assets/about_images/MES系统著作权.png'),
        },
        {
          url: require('@/assets/about_images/刻标软件著作权.jpg'),
        },
        {
          url: require('@/assets/about_images/售后工单系统著作权.jpg'),
        },
        {
          url: require('@/assets/about_images/基于一物一码追溯系统.jpg'),
        },
        {
          url: require('@/assets/about_images/托管服务系统著作权.jpg'),
        },
        {
          url: require('@/assets/about_images/ERP系统2.0.jpg'),
        },
        {
          url: require('@/assets/about_images/IOT.jpg'),
        },
        {
          url: require('@/assets/about_images/WCS.jpg'),
        },
        {
          url: require('@/assets/about_images/ERP系统著作权.jpg'),
        },
        {
          url: require('@/assets/about_images/MES系统著作权.png'),
        },
        {
          url: require('@/assets/about_images/刻标软件著作权.jpg'),
        },
        {
          url: require('@/assets/about_images/售后工单系统著作权.jpg'),
        },
        {
          url: require('@/assets/about_images/基于一物一码追溯系统.jpg'),
        },
        {
          url: require('@/assets/about_images/托管服务系统著作权.jpg'),
        },
      ]
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);

  },
  methods:{

    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },

  }
}
</script>

<style scoped>
.have-background-color{
  background-color: #f9f9f9;
  /*padding-bottom: 50px;*/
  /*padding-top: 50px;*/
}
.have-background-img{
  background-image: url("@/assets/about_images/荣誉背景.jpg");
  object-fit: contain;
  padding-bottom: 50px;
}
.module-title-box{
  text-align: center;
  padding-top: 100px;
  margin-bottom: 50px;
}
.module-title-box2{
  text-align: center;
  padding-top: 50px;
  margin-bottom: 50px;
}
.module-title{
  font-size: calc(20px + 2vw);
}
.introduce-all-box,.origin-all-box{
  align-content: center;
}
.col-md-6{
  margin: auto;
}
.introduce-text-box{
  display: flex;
  flex-direction: column;
  padding-left: 5%;
}
.origin-year-box span:nth-child(1){
  font-weight: bold;
  font-size: calc(20px + 4vw);
  color: dodgerblue;
}
.origin-year-box span:nth-child(2){
  font-size: calc(18px + 1vw);
}
.origin-text-box{
  padding-left: 10%;
}
.origin-text-box div span:nth-child(3){
  color: dimgrey;
}
.origin-text-box div{
  margin-bottom: 20px;
}
.introduce-text-box span:nth-child(1),.introduce-text-box span:nth-child(3){
  margin-bottom: 20px;
}
.introduce-text-box span:nth-child(2){
  margin-bottom: 40px;
  line-height: 1.5;
}
.honor-box{
  display: flex;
  position: relative;
  text-align: center;
  align-items: flex-end;
}
.honor-box img{
  box-shadow: 2px 2px 2px 2px rgba(128,128,128,0.5);
}
.bottom-white-box{
  width: 100%;
  background-color: white;
  box-shadow: 0 10px 15px rgba(128,128,128,0.5);
  height: 40px;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .module-title-box{
    padding-top: 50px;
    margin-bottom: 30px;
  }
  .module-title-box2{
    padding-top: 30px;
    margin-bottom: 30px;
  }
  .introduce-all-box img{
    margin-bottom: 20px;
  }
  .introduce-text-box span:nth-child(1),.introduce-text-box span:nth-child(3){
    margin-bottom: 10px;
  }
  .introduce-text-box span:nth-child(2){
    margin-bottom: 20px;
  }
  .show-img div{
    bottom: 30%;
  }

  .content-banner {
    height: 300px;
    margin-bottom: 50px;
  }
}

.images{
  display: flex;
  flex-direction: row;
}
.windows{
  overflow: hidden;
  max-width: 1160px;
}
ul li{
  float: left;
  width: 200px;
  height: 300px;
}
ul li img{
  width: 100%;
  height: 100%;
}
@keyframes run {
  to{
    transform: translateX(-2088px);
  }
}
ul{

  list-style: none;
  animation: run 39s linear infinite;
}

.honor-item{
  width: 0;
  margin: 0 30px 5px 30px;
}

.content-banner {
  background-image: url("@/assets/home_images/优势和底部的背景图.jpg");
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 400px;
  background-attachment: fixed;
}

.our-good-job-box {
  background: white;
  padding: 50px;
  height: 300px;
  box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
  margin: 2px 2px 20px;
}
</style>
