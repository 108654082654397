<template>
  <NavbarCombination :activePage="'products'"/>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img src="@/assets/products_images/banner背景.jpg" alt="">
    <div>
      <span>
        工业硬件-AGV
      </span>
      <span>
        多种安全防护，确保人机安全交互
      </span>
    </div>
  </div>
  <div class="container module-box">
    <div class="module-title-box">
      <span class="module-title">产品功能及亮点</span>
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <img src="@/assets/home_images/module.png">
      </div>
      <div class="col-md-6 col-12 texts-all-box">
        <div class="row">
          <div v-for="(content,index) in all_describes" :key="index" class="col-12 drop-down-all">
            <div @click="chooseDropdown(index)" class="drop-down-box" style="cursor: pointer;">
              <img src="@/assets/icons/chevron-right.svg" :class="{'down-nav': visible && (currentIndex === index)}" class="click-nav">
              <span class="drop-down-text1">&nbsp;{{ content.name }}</span>
            </div>
            <transition name="fade" >
              <span v-show="visible && (currentIndex===index)" class="drop-down-text2">{{ content.text }}</span>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" id="10">
    <div class="row">
      <div class="col-md-6 col-12">
        <el-carousel :height="isPhone ? '420px':'600px'" class="carousel-6-box" arrow="always">
          <el-carousel-item v-for="(img, index) in images" :key="index">
            <img :src="img.url" alt=""/>
            <span>{{img.name}}</span>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="col-md-6 col-12 decrease-box">
        <span>料箱式机器人</span>
        <span>优势</span>
        <span>
          可实现多层料箱智能拣选、存取，多料箱同时搬运；<br>
          灵活覆盖超广立体式存储空间，智能伸缩升降，高位取放货稳定；<br>
          多重安全保护，支持支持急停安全保护、避障保护、碰撞保护、电池保护等
        </span>
      </div>
    </div>
  </div>

  <div class="container all-module-box">
    <div class="row every-module-box" v-for="(part,index) in allModules" :key="index" :id="index">
      <div class="col-md-6 col-12 decrease-box" :class="isPhone ? 'order-last':''">
        <span>{{ part.name }}</span>
        <span>{{ part.text1 }}</span>
        <span>{{ part.text2 }}</span>
      </div>
      <div class="col-md-6 col-12 every-all-box" :class="(index % 2 === 0) ? '':'order-first'">
        <div class="every-img-box">
          <img :src="part.url">
          <span>{{ part.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};
export default {
  name: "HardwareHome",
  data(){
    return {
      visible: true,
      currentIndex: 10,
      isPhone: false,
      all_describes:[
        {
          name:'自动化搬运',
          text:'AGV小车能够自动的从一个地方运输货物到另一个地方，这种自动化特性使得他们在工业化应用中非常高效，尤其是在生产线上的物流中转'
        },
        {
          name:'路径规划',
          text:'可以自动的规划行驶路线，以优化运输过程，并且能够适应不断发生变化的生产需求，提高运输效率'
        },
        {
          name:'智能导航',
          text:'配备有导航导引技术，如电磁导引货二维码导引，以及惯性导航系统，这使得它们能够在没有人工干预的情况下精确地导航'
        },
        {
          name:'安全系统',
          text:'配备安全防护措施，如激光障碍物传感器和雷达扫描仪，以防止在运行过程中发生碰撞或者意外'
        },
        {
          name:'精确操作',
          text:'能够精细化作业，实现点对点自动存取，这对于需要精确控制搬运和操作的应用场景尤为重要'
        },
        {
          name:'系统兼容性',
          text:'能够与现代物流技术配合使用，与易捷信息开发的MES、ERP系统软件、仓储管理系统等，实现生产过程的集成和优化'
        },
      ],
      images: [
        {
          url: require('@/assets/products_images/多层料箱机器人2.png'),
          name: '多层料箱机器人'
        },
        {
          url: require('@/assets/products_images/伸缩升降料箱机器人1.png'),
          name: '伸缩升降料箱机器人'
        }],
      allModules: [
        {
          url: require('@/assets/products_images/托举式机器人.png'),
          name: '托举式机器人',
          text1:'优势',
          text2:'可兼容多规格多形态物料搬运，满足多业务场景作业工作；\n' +
              '柔性对接线边缓存货架/生产设备，打通产线搬运和原材料仓/线边仓存储；\n' +
              '安全可靠，支持防尘防静电',
        },
        {
          url: require('@/assets/products_images/潜伏式机器人3.png'),
          name: '潜伏式机器人',
          text1:'优势',
          text2:'效率高，直行速度快；机身小巧，适配极致的箱间距要求；\n' +
              '电池寿命长，具有极高的充放比，减少客户后续运维成本；\n' +
              '安全性能高',
        },
        {
          url: require('@/assets/products_images/多功能工作站.jpg'),
          name: '多功能工作站',
          text1:'优势',
          text2:'适配可视化看板，指导工人分拣货物；\n' +
              '高效的装卸货效率，大大提高机器人效率；\n' +
              '工人与机器人分离，人机交互友好；\n' +
              '灵活移动，快速变更安装位置，可扩展性强',
        },
        {
          url: require('@/assets/products_images/PDA.png'),
          name: '智能终端PDA',
          text1:'优势',
          text2:'无线通讯，采集的数据与数据库进行实时数据交换，数据传输快捷稳定；\n' +
              '扫描速度快，准确率高，便于现场信息采集；\n' +
              '具备较高的工业防护等级，如高温、低温、扬尘等多种工业环境',
        },
        {
          url: require('@/assets/products_images/工业平板.png'),
          name: '工业平板电脑',
          text1:'优势',
          text2:'可连接到传感器、仪器和设备，实时收集和显示数据；\n' +
              '可与其他工控设备集成，用于生产过程控制、设备调整和故障诊断；\n' +
              '可与机器人控制器和导航系统通信，实现远程控制、路径规划和任务管理；\n' +
              '与传感器和检测设备集成，用于实时质量监控和监测应用\n' +
              '多场景多行业应用，例如：仓储和物流、培训与教育等行业',
        },
        {
          url: require('@/assets/products_images/物联网边缘网关.png'),
          name: '物联网边缘网关',
          text1:'优势',
          text2:'响应快，性能强，一个盒子可以稳定接入上百个硬件（备注：视硬件协议和需要处理的业务不同有所差异）；\n' +
              '支持串口，网囗，接入设备，支持wifi，5g，网线上网；\n' +
              '支持定制开发，使用intel低功耗处理器，内存和存储空间可以定制，支持多个盒子互联协同',
        },
      ]
      }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
    //创建时执行跳转锚点位置。如果导航也跳b页面注意冲突
    this.getlocal();
  },
  methods: {
    //从本地找到id
    getlocal() {
      //找到锚点id
      let selectId = localStorage.getItem("toId");
      let toElement = document.getElementById(selectId);
      //如果对应id存在，就跳转
      if (selectId) {
        console.log(toElement, "toElement");
        toElement.scrollIntoView(true);
      }else{
        if(this.$route.query.toId){
          let selectId = this.$route.query.toId;
          let toElement = document.getElementById(selectId);
          console.log(toElement, "toElement");
          toElement.scrollIntoView(true);
        }
      }
    },

    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
    chooseDropdown(index){
      if (this.currentIndex !== index){
        this.currentIndex = index;
        if (!this.visible){
          this.visible = !this.visible;
        }
      }else {
        this.visible = !this.visible;
      }
    }
  },
}
</script>

<style scoped>
.module-box{
  position: relative;
  margin-bottom: 80px;
}
.module-title-box{
  text-align: center;
  margin-top: 100px;
}
.module-title{
  font-size: calc(20px + 2vw);
}
.drop-down-all{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}
.click-nav{
  transition: all 0.5s ease;
}
.down-nav{
  transform: rotate(90deg);
}

.drop-down-all img{
  width: 20px;
  height: 20px;
  margin-bottom: 8px;
}
.drop-down-text1{
  font-size: calc(14px + 1vw);
}
.drop-down-box{
  justify-content: center;
}
.drop-down-text2{
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: calc(14px + 0.3vw);
  width: 90%;
  margin-left: calc(20px + 0.3vw);
}
.carousel-6-box{
  position: relative;
  width: 60%; /* 根据需要调整 */
  margin: auto; /* 水平居中 */
}
.carousel-6-box div{
  margin: auto;
}
.carousel-6-box span{
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.carousel-6-box img{
  display: grid;
  margin-left: auto;
  margin-right: auto;
  height: calc(300px + 10vw);
  margin-bottom: 10px;
}
.decrease-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.decrease-box span:nth-child(1){
  font-size: calc(20px + 1vw);
  font-weight: bold;
  margin-bottom: 30px;
}
.decrease-box span:nth-child(2){
  font-size: calc(18px + 0.4vw);
  font-weight: bold;
  color: dimgrey;
  margin-bottom: 10px;
}
.decrease-box span:nth-child(3){
  font-size: calc(16px + 0.2vw);
  line-height: 2;
  color: dimgrey;
}
.every-module-box{
  margin-top: 20px;
  margin-bottom: 20px;
}
.every-module-box img{
  /*height: 500px;*/
}
.every-img-box{
  padding-left: 25%;
  padding-right: 25%;
}
.every-all-box{
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}
.all-module-box{
  margin-top: 20px;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .module-title-box{
    margin-top: 80px;
  }
  .drop-down-all img{
    width: 16px;
    height: 16px;
    margin-bottom: 6px;
  }
  .decrease-box{
    display: flex;
    flex-direction: column;
    justify-content: initial;
  }
  .decrease-box span:nth-child(1){
    margin-bottom: 20px;
  }
  .decrease-box span:nth-child(2){
    margin-bottom: 10px;
  }
  .every-module-box img{
    height: auto;
    width: auto;
  }
}
</style>