<template>
  <NavbarCombination :activePage="'products'"/>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img src="@/assets/WES_images/banner背景.jpg" alt="">
    <div>
      <span>
        轻量化，易使用，高拓展
      </span>
      <span>
        具有独立的全新的软件架构。支持独立部署，独立安装，独立维护；<br>
        能够有效应对仓库作业流程的灵活性和未来的拓展扩展性；
      </span>
    </div>
  </div>
  <div class="container module describe">
    <span>
      易捷E-WES系统概述
    </span>
    <span class="middle-text">
      具有独立的全新的软件架构。支持独立部署，独立安装，独立维护，可以作为企业的中央仓储管理系统，管理跨地域、跨组织架构的仓库、物流中心、配送中心。能够有效应对仓库作业流程的灵活性和未来的拓展扩展性；能够为仓储物流的业务管理带来直观地可视性，大幅度提升仓储作业效率；能够提供优化的资源和劳动力管理。
    </span>
  </div>
  <div class="module2">
    <div class="container describe change-the-text">
      <span>构建企业管理核心原动力</span>
    </div>
    <div>
      <!-- 自定义文字指示器 -->
      <div class="custom-indicators container">
        <div v-for="(name, index) in names" :key="index" class="container">
          <span class="navbar-4-box row">
            <div class="col">
              <div :class="{ active: currentIndex === index }" @mouseenter="choosePage(index)" @click="choosePage(index)">
                {{ name }}
              </div>
            </div>
          </span>
        </div>
      </div>
      <el-carousel :height="isPhone ? '500px':'550px'" class="container" indicator-position="none" @change="handleCarouselChange" ref="carousel">
        <el-carousel-item v-for="(img, index) in images" :key="index" class="img-text-box row">
          <img :src="img.url" alt="" class="col-md-6 col-12"/>
          <div class="texts-all-4 col-md-6 col-12">
            <span class="text-type-1">{{ img.text1 }}</span>
            <span class="text-type-2">{{ img.text2 }}</span>
            <div v-for="(text, index) in img.text3" :key="index">
              <img src="@/assets/ERP_images/图标-1.png">
              <span class="text-type-3 small-text">
                {{ text }}
              </span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>

  <div class="container change-img-bottom" :class="Animate1 ? 'logobox myanimation' : 'logobox'" ref="logobox1">
    <div class="container module describe change-the-text">
      <span>部分场景应用</span>
    </div>
    <div v-if="isPhone" class="row apply-3 no-background-color">
      <img src="@/assets/WES_images/货到人拣选.jpg" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          货到人拣选
        </span>
        <span class="middle-text">
          拣选作业过程中，由自动化物流系统将货物搬运至固定站点以供拣选，"货动人不动"；<br>
          大幅度减少了拣选作业人员的行动距离和劳动强度，实现了高于“人到货“模式数倍的拣选效率
        </span>
      </div>
    </div>
    <div v-else class="row apply-3 no-background-color">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          货到人拣选
        </span>
        <span class="middle-text">
          拣选作业过程中，由自动化物流系统将货物搬运至固定站点以供拣选，"货动人不动"；<br>
          大幅度减少了拣选作业人员的行动距离和劳动强度，实现了高于“人到货“模式数倍的拣选效率
        </span>
      </div>
      <img src="@/assets/WES_images/货到人拣选.jpg" class="col-md-6 col-12">
    </div>
  </div>
  <div class="have-background-color change-img-bottom" :class="Animate2 ? 'logobox myanimation' : 'logobox'" ref="logobox2">
    <div class="container">
      <div class="row apply-3">
        <img src="@/assets/WES_images/超多形态混存拣选.jpg" class="col-md-6 col-12">
        <div class="col-md-6 box-contain-2 col-12">
          <span class="text-type-1">
            超多形态混存拣选
          </span>
            <span class="middle-text">
            一个工作站兼容多箱整箱拣选等业务，满足多样的存拣需求；<br>
            支持一站式拣选小件、中件、大型件、异形件、重型件，柔性适应业务变化
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="container no-background-color change-img-bottom" :class="Animate3 ? 'logobox myanimation' : 'logobox'" ref="logobox3">
    <div v-if="isPhone" class="row apply-3">
      <img src="@/assets/WES_images/密集存储.jpg" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          密集存储
        </span>
        <span class="middle-text">
          利用特殊的存取方式或货架结构，实现货架深度上货物的连续存放，达到存储密度最大化；<br>
          支持0.2m一10m密集存储，在各种仓高情况下显著提升存储密度
        </span>
      </div>
    </div>
    <div v-else class="row apply-3">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          密集存储
        </span>
        <span class="middle-text">
          利用特殊的存取方式或货架结构，实现货架深度上货物的连续存放，达到存储密度最大化；<br>
          支持0.2m一10m密集存储，在各种仓高情况下显著提升存储密度
        </span>
      </div>
      <img src="@/assets/WES_images/密集存储.jpg" class="col-md-6 col-12">
    </div>
  </div>

  <div class="have-background-img">
    <div class="container describe change-the-text-top">
      <span>案例分享</span>
    </div>
    <div v-if="isPhone" class="container cases-all">
      <div class="row">
        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/WES_images/凯马精工-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>凯马精工</span>
              <span>"通过自动识别系统，自动完成分拣，自动判断生产现场人员、设备、工位的占用情况，实现智能调度工位资源"</span>
            </div>
          </div>
        </div>
        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/WES_images/埃肯硅材料-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>埃肯硅材料</span>
              <span>"借助易捷E-WES系统实现与上层系统结合，根据物料形态、冷热程度、分区指定管理，不同分区采用AGV智能搬运和系统引导人工叉车扫码搬运"</span>
            </div>
          </div>
        </div>
        <div class="case">
            <div class="benchmark-box">
              <img src="@/assets/WES_images/默飓电器-移动端.jpg" alt="erp">
              <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
                <span>默飓电气</span>
                <span>"通过实施易捷E-WES系统，实现自动投料和智能调动产出物搬运；线边库集成管理"</span>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div v-else class="container cases-all">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/WES_images/凯马精工.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>凯马精工</span>
              <span >"通过自动识别系统，自动完成分拣，自动判断生产现场人员、设备、工位的占用情况，实现智能调度工位资源"</span>
            </div>
          </div>
        </div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/WES_images/埃肯硅材料.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>埃肯硅材料</span>
              <span >"借助易捷E-WES系统实现与上层系统结合，根据物料形态、冷热程度、分区指定管理，不同分区采用AGV智能搬运和系统引导人工叉车扫码搬运"</span>
            </div>
          </div>
        </div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/WES_images/默飓电气.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>默飓电气</span>
              <span >"通过实施易捷E-WES系统，实现自动投料和智能调动产出物搬运；线边库集成管理"</span>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>

</template>

<script>

import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};

export default {
  name: "ERPPlan",
  components: { RightNavbar, NavbarCombination},
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      Animate5: false,
      isPhone: false,
      currentIndex: 0, // 当前轮播图的索引
      activeIndex: 0,
      names:[
        '多模式拣选','个性化搬运','机器人调度','多设备物联协同'
      ],
      images: [{
        url: require('@/assets/WES_images/多模式拣选.jpg'),
        text1:'多种拣货方式',
        text2:'人机交互友好，为不同的拣货方式提供对应的便捷出入库流程',
        text3:['拣选：针对单一订单进行货品拣选，再根据订单进行分包处理；',
            '播种式拣货：针对多个波次信息或订单信息，对每一份订单进行货品拣选']
      },
        {
          url: require('@/assets/WES_images/个性化拣选.jpg'),
          text1:'个性化搬运策略',
          text2:'丰富搬运策可单一配置，也可按照情况进行组合生效',
          text3:['多个策略条件自由组合',
            '不同环境配置不同AGV实现各种场景业务']
        },
        {
          url: require('@/assets/WES_images/机器人调度.jpg'),
          text1:'人工智能调度算法',
          text2:'机器人互相合作又互不影响，发挥最大效能',
          text3:['多路径最优规划，提供最短路径形式、避让控制、路径重新规划控制等多种处理机制；',
            '交通动态管理，实现动态行驶方向管理；']
        }, {
          url: require('@/assets/WES_images/多设备物联协同.jpg'),
          text1:'多设备物联协同',
          text2:'提供透明化、智能化的管理方式',
          text3:['支持缓冲区管理、跨楼层/区域搬运等业务场景',
            '提供完善接口，向上可与多品牌ERP系统、MES系统实现对接；',
          '向下与生产现场的设备实现对接，包括立体智能货架、机械臂等']
        },

      ]
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener('scroll',this.scrollToTop);
    next();
  },
  methods:{
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
      this.Animate2=!isElementNotInViewport(this.$refs.logobox2)
      this.Animate3=!isElementNotInViewport(this.$refs.logobox3)
      this.Animate4=!isElementNotInViewport(this.$refs.logobox4)
      this.Animate5=!isElementNotInViewport(this.$refs.logobox5)
    },
    handleCarouselChange(currentIndex) {
      this.currentIndex = currentIndex;
    },
    choosePage(index) {
      if (index >= 0 && index < 4) { // 确保索引在有效范围内
        this.$refs.carousel.setActiveItem(index)
      }
      console.log(this.activeIndex)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
  }
}
</script>

<style scoped>
.describe{
  display: flex;
  flex-direction: column;
  width: 90%;
}
.describe span:nth-child(1){
  font-size: calc(20px + 1.5vw);
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.change-the-text{
  margin-top: 30px;
  margin-bottom: 20px;
}
.change-the-text-top{
  padding-top: 30px;
}
.custom-indicators{
  text-align: center;
  display: flex;
}
.custom-indicators div{
  font-size: calc(8px + 1vw);
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.custom-indicators * {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}
.custom-indicators .active{
  color: cornflowerblue!important;
}
.custom-indicators .active::after {
  content: ""; /* 伪元素必须有内容 */
  position: absolute; /* 相对于a元素定位 */
  left: 0; /* 从左边开始 */
  right: 0; /* 到右边结束 */
  bottom: -10px; /* 下划线与文字之间的间隔 */
  height: 4px; /* 下划线的厚度 */
  background-color: cornflowerblue; /* 下划线的颜色 */
  opacity: 1!important;
  transition: max-width 10s ease-in-out; /* 过渡效果 */
}

.img-text-box{
  display: flex;
  align-items: center;
}
.img-text-box img:nth-child(1){
  margin-bottom: 10px;
}
.texts-all-4 div{
  display: flex;
  flex-direction: row;
}
.texts-all-4 span{
  display: flex;
  flex-direction: row;
}
.texts-all-4 img{
  height: 16px;
  width: 16px;
  margin-top: 5px;
  margin-right: 5px;
}
.text-type-1{
  font-weight: bold;
  font-size: calc(16px + 1vw);
}
.text-type-2{
  font-size: calc(10px + 0.8vw);
  margin-bottom: 20px;
}
.text-type-3{
  color: gray;
}

.el-carousel__item{
  width: auto;
}
.have-background-img{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
  width: 100%;
  padding-bottom: 30px;
}

.box-contain-2 {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-left: 5%;
}
.box-contain-2 span:nth-child(2){
  margin-top: 10px;
  color: gray;
  line-height: 2;
  width: 90%;
}

.have-background-color{
  background-color: #f9f9f9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.no-background-color{
  padding-top: 50px;
  padding-bottom: 50px;
}
.texts span:nth-child(1){
  justify-content: center;
  font-size: 30px;
  margin-bottom: 20px;
}
.texts span:nth-child(2){
  font-size: 20px;
}

.benchmark-box img:hover{
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .show-img div{
    bottom: 14%;
  }
  .texts-all-4 img{
    margin-top: 0;
  }
  .text-type-2{
    font-size: calc(10px + 0.8vw);
    margin-bottom: 10px;
  }
  .change-img-bottom img{
    margin-bottom: 20px;
  }
  .box-contain-2 span:nth-child(2){
    width: 100%;
  }
  .benchmark-box img{
    width: 100%;
  }
  .texts {
    margin-top: calc(3px + 1vw);
  }
  .texts span:nth-child(1){
    justify-content: center;
    margin-bottom: 0;
  }
  .texts span:nth-child(2){
    padding-bottom: 0;
    font-size: 16px;
  }
}
</style>