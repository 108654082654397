<template>
  <NavbarCombination :activePage="'products'"/>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img v-if="isPhone" src="@/assets/IoT_images/banner背景-移动端.jpg" alt="">
    <img v-else src="@/assets/IoT_images/banner背景.jpg" alt="">
    <div>
      <span>
        推动产业转型升级，助力智能制造
      </span>
      <span>
        多种接入方式，设备之间进行物联简单快捷
      </span>
    </div>
  </div>
  <div class="container module describe">
    <span>
      易捷IoT-Edge计算服务概述
    </span>
    <span class="middle-text">
      工厂物联系统是工厂硬件物联的核心解决方案，为易捷信息开发的所有软件提供物联网硬件支持。明确智能工厂建设/改造目标，规划清晰的实现路径，并具备数字化思路
    </span>
  </div>
  <div class="new-color-module">
    <div class="container describe change-the-text">
      <span>智能制造的主线</span>
    </div>
    <div>
      <!-- 自定义文字指示器 -->
      <div class="custom-indicators container">
        <div v-for="(name, index) in names" :key="index" class="container">
          <span class="navbar-4-box row">
            <div class="col">
              <div :class="{ active: currentIndex === index }" @mouseenter="choosePage(index)" @click="choosePage(index)">
                {{ name }}
              </div>
            </div>
          </span>
        </div>
      </div>
      <el-carousel :height="isPhone ? '500px':'550px'" class="container" indicator-position="none" @change="handleCarouselChange" ref="carousel">
        <el-carousel-item v-for="(img, index) in images" :key="index" class="img-text-box row">
          <img :src="img.url" alt="" class="col-md-6 col-12"/>
          <div class="texts-all-4 col-md-6 col-12">
            <span class="text-type-1">{{ img.text1 }}</span>
            <span class="text-type-2">{{ img.text2 }}</span>
            <div v-for="(text, index) in img.text3" :key="index">
              <img src="@/assets/ERP_images/图标-1.png">
              <span class="text-type-3 small-text">
                {{ text }}
              </span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>

  <div class="module2 have-background-img">
    <div class="container describe change-the-text">
      <span>工厂设备结合软件，打造数智工厂</span>
    </div>
    <div class="container hardware-and-software">
      <div v-if="isPhone">
        <img src="@/assets/IoT_images/软硬结合流程图-移动端.png">
        <img src="@/assets/IoT_images/智慧工厂-移动端.png" >
      </div>
      <div v-else>
        <img src="@/assets/IoT_images/软硬结合_工厂.png">
      </div>
    </div>
  </div>

  <div class="container change-img-bottom" :class="Animate1 ? 'logobox myanimation' : 'logobox'" ref="logobox1">
    <div class="container module describe change-the-text">
      <span>部分场景应用</span>
    </div>
    <div v-if="isPhone" class="row apply-3 no-background-color">
      <img src="@/assets/IoT_images/iot边缘联接.jpg" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          IoT边缘联结
        </span>
        <span class="middle-text">
          通过边缘计算网关支持的各种物联接口，支持多设备多协议系统接入，实现海量终端设备统一接入管理，降低协同成本
        </span>
      </div>
    </div>
    <div v-else class="row apply-3 no-background-color">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          IoT边缘联结
        </span>
        <span class="middle-text">
          通过边缘计算网关支持的各种物联接口，支持多设备多协议系统接入，实现海量终端设备统一接入管理，降低协同成本
        </span>
      </div>
      <img src="@/assets/IoT_images/iot边缘联接.jpg" class="col-md-6 col-12">
    </div>
  </div>
  <div class="have-background-color change-img-bottom" :class="Animate2 ? 'logobox myanimation' : 'logobox'" ref="logobox2">
    <div class="container">
      <div class="row apply-3">
        <img src="@/assets/IoT_images/智能数据分析.jpg" class="col-md-6 col-12">
        <div class="col-md-6 box-contain-2 col-12">
          <span class="text-type-1">
            智能分析数据
          </span>
          <span class="middle-text">
            在产线部署传感器设备采集各生产环节的数据，统一管理，通过相关算法进行分析加工，形成可供决策的有价值数据，实现工业生产流程数字化
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="have-background-img">
    <div class="container describe change-the-text-top">
      <span>案例分享</span>
    </div>
    <div v-if="isPhone" class="container cases-all">
      <div class="row">
        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/IoT_images/凯马精工-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>凯马精工</span>
              <span>"通过实施易捷MES系统，实现生产全过程管控；通过派工和PDA移动扫码报工，极大提升现场作业进度采集效率"</span>
            </div>
          </div>
        </div>
        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/IoT_images/坤博材料-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>坤博材料</span>
              <span>"实现与上层设备对接，与生产设备联接，实现生产数据取数，如开机时长、生产情况等"</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container cases-all">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/IoT_images/凯马精工.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>凯马精工</span>
              <span >"通过实施易捷MES系统，实现生产全过程管控；通过派工和PDA移动扫码报工，极大提升现场作业进度采集效率"</span>
            </div>
          </div>
        </div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/IoT_images/坤博材料.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>坤博材料</span>
              <span >"实现与上层设备对接，与生产设备联接，实现生产数据取数，如开机时长、生产情况等"</span>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </div>

</template>

<script>

import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};

export default {
  name: "ERPPlan",
  components: { RightNavbar, NavbarCombination},
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      Animate5: false,
      isPhone: false,
      currentIndex: 0, // 当前轮播图的索引
      activeIndex: 0,
      names:[
        '多接入方式','统一共享站','灵活部署'
      ],
      images: [{
        url: require('@/assets/IoT_images/多接入方式.jpg'),
        text1:'接入方式多样化',
        text2:'可灵活简便地搭建复杂物联网应用',
        text3:['支持串囗、PLC、有线网、无线网等多种接入方式；',
            '独特的场景自定义功能，满足各类设备和接入场景要求；',
            '通过“loT-Edge”数据共享站快速分发对接，将数据接入各个系统，实现真正的工厂物联']
      },
        {
          url: require('@/assets/IoT_images/统一共享站.jpg'),
          text1:'数据共享分发',
          text2:'实现设备与物联网平台之间稳定可靠地双向通信',
          text3:['海量设备的网络接入管理；',
            '海量数据统一汇聚，包括存储、整理及对外的数据开发；',
            '提供行业共性的计算能力服务，比如人工智能、深度学习等']
        },
        {
          url: require('@/assets/IoT_images/灵活部署.jpg'),
          text1:'安全灵活部署',
          text2:'支持分布式集群部署，具备无缝扩展能力',
          text3:['海量设备的接入通过边缘计算进行实时协同和同步更新控制；',
            '高性能低延迟服务，降低硬件消耗，多站标准化部署；',
            '对于已有的老旧设备或特殊设备，也可实现连接']
        },

      ]
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener('scroll',this.scrollToTop);
    next();
  },
  methods:{
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
      this.Animate2=!isElementNotInViewport(this.$refs.logobox2)
      this.Animate3=!isElementNotInViewport(this.$refs.logobox3)
      this.Animate4=!isElementNotInViewport(this.$refs.logobox4)
      this.Animate5=!isElementNotInViewport(this.$refs.logobox5)
    },
    handleCarouselChange(currentIndex) {
      this.currentIndex = currentIndex;
    },
    choosePage(index) {
      if (index >= 0 && index < 3) { // 确保索引在有效范围内
        this.$refs.carousel.setActiveItem(index)
      }
      console.log(this.activeIndex)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
  }
}
</script>

<style scoped>
.describe{
  display: flex;
  flex-direction: column;
  width: 90%;
}
.describe span:nth-child(1){
  font-size: calc(20px + 1.5vw);
  text-align: center;
  margin-bottom: 20px;
  padding-top: 20px;
}
.change-the-text{
  margin-bottom: 20px;
}
.change-the-text-top{
  padding-top: 30px;
}
.custom-indicators{
  text-align: center;
  display: flex;
}
.custom-indicators div{
  font-size: calc(8px + 1vw);
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.custom-indicators * {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}
.custom-indicators .active{
  color: cornflowerblue!important;
}
.custom-indicators .active::after {
  content: ""; /* 伪元素必须有内容 */
  position: absolute; /* 相对于a元素定位 */
  left: 0; /* 从左边开始 */
  right: 0; /* 到右边结束 */
  bottom: -10px; /* 下划线与文字之间的间隔 */
  height: 4px; /* 下划线的厚度 */
  background-color: cornflowerblue; /* 下划线的颜色 */
  opacity: 1!important;
  transition: max-width 10s ease-in-out; /* 过渡效果 */
}

.img-text-box{
  display: flex;
  align-items: center;
}
.img-text-box img:nth-child(1){
  margin-bottom: 10px;
}
.texts-all-4 div{
  display: flex;
  flex-direction: row;
}
.texts-all-4 span{
  display: flex;
  flex-direction: row;
}
.texts-all-4 img{
  height: 16px;
  width: 16px;
  margin-top: 5px;
  margin-right: 5px;
}
.text-type-1{
  font-weight: bold;
  font-size: calc(16px + 1vw);
}
.text-type-2{
  font-size: calc(10px + 0.8vw);
  margin-bottom: 20px;
}
.text-type-3{
  color: gray;
}

.el-carousel__item{
  width: auto;
}
.have-background-img{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
  width: 100%;
  padding-bottom: 30px;
}

.box-contain-2 {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-left: 5%;
}
.box-contain-2 span:nth-child(2){
  margin-top: 10px;
  color: gray;
  line-height: 2;
  width: 90%;
}

.have-background-color{
  background-color: #f9f9f9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.no-background-color{
  padding-top: 50px;
  padding-bottom: 50px;
}
.texts span:nth-child(1){
  justify-content: center;
  font-size: 30px;
  margin-bottom: 20px;
}
.texts span:nth-child(2){
  font-size: 20px;
}

.benchmark-box img:hover{
  transform: scale(1.1);
}

.hardware-and-software{
  text-align: center;
}

@media (max-width: 768px) {
  .texts-all-4 img{
    margin-top: 0;
  }
  .text-type-2{
    font-size: calc(10px + 0.8vw);
    margin-bottom: 10px;
  }
  .change-img-bottom img{
    margin-bottom: 20px;
  }
  .box-contain-2 span:nth-child(2){
    width: 100%;
  }
  .benchmark-box img{
    width: 100%;
  }
  .texts {
    margin-top: calc(3px + 1vw);
  }
  .texts span:nth-child(1){
    justify-content: center;
    margin-bottom: 0;
  }
  .texts span:nth-child(2){
    padding-bottom: 0;
    font-size: 16px;
  }
  .have-background-img{
    background-image: url("@/assets/IoT_images/软硬结合背景图-移动端.jpg");
    width: 100%;
    padding-bottom: 30px;
  }
}
</style>