<template>
  <NavbarCombination active-page="products"></NavbarCombination>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img src="@/assets/products_images/banner背景.jpg" alt="" style="text-align: center;">
    <div>
      <span>
        软硬可结合，实现互联
      </span>
      <span>
        让每个个体也能拥抱整个行业！
      </span>
    </div>
  </div>

  <div class="module2">
    <div class="container module">
      <div class="row">
        <div class="title">
          <div class="big-title">
            <span>产品简介及优势</span>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row" style="padding: 20px 5px;">
        <router-link to="/product/ERP" class="col-md-3 col-6 prod-div">
          <div :style="backgroundProd('erp.jpg')">
            <div><h5><span>ERP</span>企业资源计划</h5></div>
          </div>
        </router-link>
        <router-link to="/product/MES" class="col-md-3 col-6 prod-div ">
          <div :style="backgroundProd('MES.jpg')">
            <div><h5><span>MES</span>制造执行系统</h5></div>
          </div>
        </router-link>
        <router-link to="/product/WES" class="col-md-6 col-6 prod-div" >
          <div :style="backgroundProd('E-WES.jpg')" style="background-size: 100%;">
            <div><h5><span>E-WES</span>仓库执行系统</h5></div>
          </div>
        </router-link>

        <router-link to="/product/ASMS" class="col-md-3 col-6 prod-div">
          <div :style="backgroundProd('售后.jpg')">
            <div><h5><span>E-ASMS</span>售后管理</h5></div>
          </div>
        </router-link>
        <router-link to="/product/IotEdge" class="col-md-6 col-6 prod-div">
          <div :style="backgroundProd('IOT-Edge.jpg')">
            <div><h5><span>IOT-Edge</span>物联网边缘计算服务</h5></div>
          </div>
        </router-link>
        <router-link to="/product/ObjectCode" class="col-md-3 col-6 prod-div">
          <div :style="backgroundProd('一物一码.jpg')">
            <div><h5><span>一物一码</span></h5></div>
          </div>
        </router-link>

        <router-link to="/product/MobileApp" class="col-md-6 col-6 prod-div">
          <div v-if="isPhone"  :style="backgroundProd('移动端应用.jpg')">
            <div><h5><span>移动端应用</span></h5></div>
          </div>
          <div v-else  :style="backgroundProd('移动端应用.jpg')" style="background-size: 100%;">
            <div><h5><span>移动端应用</span></h5></div>
          </div>
        </router-link>
        <router-link to="/product/HardwareHome" class="col-md-6 col-6 prod-div">
          <div :style="backgroundProd('智能装备.jpg')">
            <div><h5><span>智能装备</span></h5></div>
          </div>
        </router-link>
      </div>
    </div>
  </div>

  <div class="module2">
    <div class="container" :class="Animate1 ? 'logobox myanimation' : 'logobox'" ref="logobox1">
      <div class="row">
        <div class="blocks5 col-md col-12">
          <img src="@/assets/icons/一体化.png">
          <span class="largest-text">
            一体化
          </span>
          <span class="middle-text">
            专注于软件开发与服务，自主研发、创新实力强大
          </span>
        </div>
        <div class="blocks5 col-md col-12">
          <img src="@/assets/icons/移动端.png">
          <span class="largest-text">
            移动端
          </span>
          <span class="middle-text">
            先进技术架构、安全体系和权限控制
          </span>
        </div>
        <div class="blocks5 col-md col-12">
          <img src="@/assets/icons/高度拓展.png">
          <span class="largest-text">
            高度拓展
          </span>
          <span class="middle-text">
            不同行业，不同地区，不同用户，每天都在同步使用我们的软件
          </span>
        </div>
        <div class="blocks5 col-md col-12">
          <img src="@/assets/icons/安全稳定.png">
          <span class="largest-text">
            安全稳定
          </span>
          <span class="middle-text">
            按需求定制，匹配企业管理，易于理解，上手更快
          </span>
        </div>
        <div class="blocks5 col-md col-12">
          <img src="@/assets/icons/专业.png">
          <span class="largest-text">
            专业
          </span>
          <span class="middle-text">
            无需上时间运行测试，快速投入应用
          </span>
        </div>
      </div>
    </div>

  </div>

  <div class="module2 bc-color-change">
    <div class="title">
      <div class="big-title">
        <span>硬件产品及特点</span>
      </div>
    </div>
    <el-carousel :height="isPhone ? '420px':'600px'" class="carousel-6-box" arrow="always" :type="isPhone ? '':'card'">
      <el-carousel-item v-for="(img, index) in images" :key="index">
        <img :src="img.url" alt=""/>
        <span>{{img.name}}</span>
      </el-carousel-item>
    </el-carousel>
  </div>

  <div class="module2 bc-color-change">
    <div class="container">
      <div class="row">
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            01
          </span>
          <span class="largest-text">
            智能拣选搬运
          </span>
          <span class="middle-text">
            自主拣选、智能搬运、定位精度高
          </span>
        </div>
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            02
          </span>
          <span class="largest-text">
            存储范围超广覆盖
          </span>
          <span class="middle-text">
            存储范围覆盖0.35m到10m立体空间
          </span>
        </div>
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            03
          </span>
          <span class="largest-text">
            高速稳定移动
          </span>
          <span class="middle-text">
            速度高达1.5m
          </span>
        </div>
      </div>
      <div class="row">
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            04
          </span>
          <span class="largest-text">
            多货箱搬运
          </span>
          <span class="middle-text">
            机器人背篓可灵活定制层数，拥有多缓存位
          </span>
        </div>
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            05
          </span>
          <span class="largest-text">
            无线网络通讯
          </span>
          <span class="middle-text">
            设备全生命周期数据收集不健全、现场服务数据难使用，无法与ERP、CRM等系统数据整合，人工处理数据耗时久，效率低，难以及时发现问题。
          </span>
        </div>
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            06
          </span>
          <span class="largest-text">
            一体化
          </span>
          <span class="middle-text">
            专注于软件开发与服务，自主研发、创新实力强大
          </span>
        </div>
      </div>
      <div class="row">
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            07
          </span>
          <span class="largest-text">
            混合拣选
          </span>
          <span class="middle-text">
            兼容纸箱/料箱，多尺寸货箱
          </span>
        </div>
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            08
          </span>
          <span class="largest-text">
            机身高度柔性
          </span>
          <span class="middle-text">
            可灵活前进后退、灵活穿梭矮门洞和矮横梁
          </span>
        </div>
        <div class="blocks9 col-md col-12">
          <span class="small-text">
            09
          </span>
          <span class="largest-text">
            最优解决方案
          </span>
          <span class="middle-text">
            根据不同应用场景量身定制最优方案
          </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};

export default {
  name: "ProductsCenter",
  components: { RightNavbar, NavbarCombination},
  data(){
    return{
      Animate1: false,
      isPhone: false,
      currentIndex: 0,
      images: [
          {
        url: require('@/assets/products_images/多层料箱机器人2.png'),
        name: '多层料箱机器人'
      },
        {
          url: require('@/assets/products_images/托举式机器人.png'),
          name: '托举式机器人'
        },
        {
          url: require('@/assets/products_images/潜伏式机器人3.png'),
          name: '潜伏式机器人'
        },{
          url: require('@/assets/products_images/伸缩升降料箱机器人1.png'),
          name: '伸缩升降料箱机器人'
        },{
          url: require('@/assets/products_images/PDA.png'),
          name: 'PDA智能终端'
        },{
          url: require('@/assets/products_images/工业平板.png'),
          name: '工业平板电脑'
        },{
          url: require('@/assets/products_images/物联网边缘网关.png'),
          name: '物联网边缘网关'
        },

      ]
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
    },
    backgroundProd(img){
      let imgUrl = require('@/assets/home_images/'+img)
      return {
        // 使用计算属性来动态生成内联样式
        backgroundImage: `url(${imgUrl})`,
        height:"100%",
        backgroundSize: 'auto 100%', // 示例：背景图覆盖
        backgroundPosition: 'center' // 示例：背景图居中
      };
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },


  },
}
</script>

<style scoped>
.big-title{
  font-size: calc(20px + 2vw);
  text-align: center;
  margin-bottom: 10px;
}
.blocks5{
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-left: 8px;
  height: 400px;
  box-shadow: rgba(211,211,211,0.5) 0 0 16px;
  margin-bottom: 50px;
}

.blocks5 img{
  margin-top: 20%;
  width: calc(1.5vw + 20px);
  height: calc(1.5vw + 20px);
  margin-bottom: 10%;
}
.blocks5>span:nth-child(2) {
  font-weight: bold;
  margin-bottom: 20%;
}

.blocks9{
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-left: 8px;
  height: 250px;
  box-shadow: rgba(211,211,211,0.5) 0 0 16px;
  margin-bottom: 16px;
}
.blocks9>span:nth-child(1){
  margin-top: 12%;
  font-weight: bold;
}
.blocks9>span:nth-child(2){
  font-weight: bold;
  margin-bottom: 3%;
}

.bc-color-change{
  background-color: #F6F8FA;
}

.carousel-6-box{
  position: relative;
  width: 60%; /* 根据需要调整 */
  margin: auto; /* 水平居中 */
}
.carousel-6-box div{
  margin: auto;
}
.carousel-6-box span{
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.carousel-6-box img{
  display: grid;
  margin-left: auto;
  margin-right: auto;
  height: calc(300px + 10vw);
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .blocks5,.blocks9{
    height: auto;
    margin:5px 5% 10px 5%;
    width: 90%;
    box-shadow: rgba(211,211,211,0.5) 0 0 20px;
  }
  .blocks5 img{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .blocks9>span:nth-child(1){
    margin-top: 8%;
    margin-bottom: 0;
  }
  .blocks5>span:nth-child(2),.blocks9>span:nth-child(2){
    margin-bottom: 10px;
  }
  .blocks5>span:nth-child(3),.blocks9>span:nth-child(3){
    margin-bottom: 10px;
  }
}
</style>
