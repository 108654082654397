<template>
  <div :class="isPhone ? 'name-text box-offset' : 'name-text'">
    <span class="middle-text">
      {{ currentPage }}
    </span>
    <span class="middle-text">
      咨询电话：0575-81383566
    </span>
  </div>
</template>

<script>
export default {
  name: "EveryName",
  props: {
    currentPage: {
      type: String, // 指定 prop 的类型，这里是字符串
      required: true, // 指定这个 prop 是否是必需的
      default: '易捷ERP企业资源计划' // 提供一个默认值，以防没有传递
    }
  },
  data() {
    return {
      isPhone: false,
      screenWidthSize: window.innerWidth,
    }
  },
  // 获取屏幕宽度
  mounted() {
    this.updateScreenSize();
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeUnmount() {
    // 移除事件监听器
    window.removeEventListener('resize', this.updateScreenSize);
  },
  methods: {
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      // 根据屏幕宽度设置isMobile的值
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
  }
}
</script>

<style scoped>
.name-text{
  box-shadow: darkgrey 0px 0px 2px;
  display: flex;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.name-text span:nth-child(1){
  font-weight: bold;
  width: 50%;
}
.name-text span:nth-child(2){
  width: 50%;
}
</style>