<template>
  <NavbarCombination :activePage="'products'"/>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img v-if='isPhone' src="@/assets/one_object_one_code/banner背景-移动端.jpg" alt="">
    <img v-else src="@/assets/one_object_one_code/banner背景.jpg" alt="">
    <div>
      <span>
        操作灵活且可追溯
      </span>
      <span>
        为产品雕刻唯一身份信息，实时监控产品流向
      </span>
    </div>
  </div>
  <div class="container module describe">
    <span>
      一物一码概述
    </span>
    <span class="middle-text">
      工厂物联系统是工厂硬件物联的核心解决方案，为易捷信息开发的所有软件提供物联网硬件支持。明确智能工厂建设/改造目标，规划清晰的实现路径，并具备数字化思路
    </span>
  </div>
  <div class="new-color-module">
    <div class="container describe change-the-text">
      <span>简化您的标记过程</span>
    </div>
    <div>
      <!-- 自定义文字指示器 -->
      <div class="custom-indicators container">
        <div v-for="(name, index) in names" :key="index" class="container">
          <span class="navbar-4-box row">
            <div class="col">
              <div :class="{ active: currentIndex === index }" @mouseenter="choosePage(index)" @click="choosePage(index)">
                {{ name }}
              </div>
            </div>
          </span>
        </div>
      </div>
      <el-carousel :height="isPhone ? '500px':'550px'" class="container" indicator-position="none" @change="handleCarouselChange" ref="carousel">
        <el-carousel-item v-for="(img, index) in images" :key="index" class="img-text-box row">
          <img :src="img.url" alt="" class="col-md-6 col-12"/>
          <div class="texts-all-4 col-md-6 col-12">
            <span class="text-type-1">{{ img.text1 }}</span>
            <span class="text-type-2">{{ img.text2 }}</span>
            <div v-for="(text, index) in img.text3" :key="index">
              <img src="@/assets/ERP_images/图标-1.png">
              <span class="text-type-3 small-text">
                {{ text }}
              </span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>


  <div class="container change-img-bottom" :class="Animate1 ? 'logobox myanimation' : 'logobox'" ref="logobox1">
    <div class="container module describe change-the-text">
      <span>部分场景应用</span>
    </div>
    <div v-if="isPhone" class="row apply-3 no-background-color">
      <img src="@/assets/one_object_one_code/产品赋码.jpg" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          产品赋码
        </span>
        <span class="middle-text">
          对产品进行赋码，产品信息将被录入系统；<br>
          对产品单层或多层包装赋码，实现单层或多层包装数据采集和关联关系，来掌握包装对应所有产品的流通数据
        </span>
      </div>
    </div>
    <div v-else class="row apply-3 no-background-color">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          产品赋码
        </span>
        <span class="middle-text">
          对产品进行赋码，产品信息将被录入系统；<br>
          对产品单层或多层包装赋码，实现单层或多层包装数据采集和关联关系，来掌握包装对应所有产品的流通数据
        </span>
      </div>
      <img src="@/assets/one_object_one_code/产品赋码.jpg" class="col-md-6 col-12">
    </div>
  </div>
  <div class="have-background-color change-img-bottom" :class="Animate2 ? 'logobox myanimation' : 'logobox'" ref="logobox2">
    <div class="container">
      <div class="row apply-3">
        <img src="@/assets/one_object_one_code/渠道流通.jpg" class="col-md-6 col-12">
        <div class="col-md-6 box-contain-2 col-12">
          <span class="text-type-1">
            渠道流通
          </span>
          <span class="middle-text">
            产品发货、收货等流转过程，都需扫描产品二维码，系统实施记录扫码数据；<br>
            管理者通过系统后台便能查看产品流通方向。是否窜货一目了然
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="container change-img-bottom" :class="Animate3 ? 'logobox myanimation' : 'logobox'" ref="logobox3">
    <div v-if="isPhone" class="row apply-3 no-background-color">
      <img src="@/assets/one_object_one_code/消费者扫码.jpg" class="col-md-6 col-12">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          消费者扫码
        </span>
        <span class="middle-text">
          购买产品后，消费者可扫描二维码查溯源、验真伪，提升产品信誉度；<br>
          精准触达消费者，构建企业与用户之间的数字桥梁
        </span>
      </div>
    </div>
    <div v-else class="row apply-3 no-background-color">
      <div class="col-md-6 box-contain-2 col-12">
        <span class="text-type-1">
          消费者扫码
        </span>
        <span class="middle-text">
          购买产品后，消费者可扫描二维码查溯源、验真伪，提升产品信誉度；<br>
          精准触达消费者，构建企业与用户之间的数字桥梁
        </span>
      </div>
      <img src="@/assets/one_object_one_code/消费者扫码.jpg" class="col-md-6 col-12">
    </div>
  </div>

  <div class="have-background-img">
    <div class="container describe change-the-text-top">
      <span>案例分享</span>
    </div>
    <div v-if="isPhone" class="container cases-all">
      <div class="row">
        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/美多电器-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>美多电器</span>
              <span>"借助易捷刻标软件系统，对接上层系统ERP和激光刻标机，为产品雕刻唯一识别码；将打印出的码标签贴于包装箱上"</span>
            </div>
          </div>
        </div>

        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/卡梦帝电器-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>卡梦帝电器</span>
              <span>"借助易捷软件系统的发码功能，将产品识别码交由供应商打印并粘贴，快捷便利"</span>
            </div>
          </div>
        </div>

        <div class="case">
          <div class="benchmark-box">
            <img src="@/assets/one_object_one_code/五行电器-移动端.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>五行电器</span>
              <span>"通过易捷ERP系统为产品发码，并使用绑定外码功能，使产品能同时使用两种码，且性质相同"</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container cases-all">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/美多电器.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>美多电器</span>
              <span >"借助易捷刻标软件系统，对接上层系统ERP和激光刻标机，为产品雕刻唯一识别码；将打印出的码标签贴于包装箱上"</span>
            </div>
          </div>
        </div>

        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/ERP_images/卡梦帝电器.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>卡梦帝电器</span>
              <span >"借助易捷软件系统的发码功能，将产品识别码交由供应商打印并粘贴，快捷便利"</span>
            </div>
          </div>
        </div>

        <div class="col case">
          <div class="benchmark-box">
            <img src="@/assets/one_object_one_code/五行电器.jpg" alt="erp">
            <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
              <span>五行电器</span>
              <span >"通过易捷ERP系统为产品发码，并使用绑定外码功能，使产品能同时使用两种码，且性质相同"</span>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>

</template>

<script>

import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};

export default {
  name: "ERPPlan",
  components: { RightNavbar, NavbarCombination},
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      Animate5: false,
      isPhone: false,
      currentIndex: 0, // 当前轮播图的索引
      activeIndex: 0,
      names:[
        '产品身份证','操作便捷','售后服务及时'
      ],
      images: [{
        url: require('@/assets/one_object_one_code/产品身份码.jpg'),
        text1:'产品识别码唯一',
        text2:'不易被伪造、被破坏，保存周期长',
        text3:['易捷自主研发的刻标软件系统，可对接激光刻标机及上层系统ERP，为产品雕刻识别码，赋予每个产品独一无二的“身份证”']
      },
        {
          url: require('@/assets/one_object_one_code/操作便捷.jpg'),
          text1:'操作便捷',
          text2:'简单的输入，精确的输出',
          text3:['产品从生产、组装到质检，从入库到出库，每一次流转过程，都会搭配电脑客户端的扫码枪，或者手机APP、微信小程序、PDA智能终端的扫码功能，进行扫码录入操作，更加快捷准确']
        },
        {
          url: require('@/assets/one_object_one_code/售后服务及时.jpg'),
          text1:'售后服务及时',
          text2:'一键扫码申请售后',
          text3:['产品独一无二的“身份证”，客户可通过识别码查询产品的各种信息，同时支持客户自主扫码在线提交售后工单。售后系统与ERP、MES系统数据互通，实现统一调拨、收发货等流程管理，与您的客户无缝连接。']
        },

      ]
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener('scroll',this.scrollToTop);
    next();
  },
  methods:{
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
      this.Animate2=!isElementNotInViewport(this.$refs.logobox2)
      this.Animate3=!isElementNotInViewport(this.$refs.logobox3)
      this.Animate4=!isElementNotInViewport(this.$refs.logobox4)
      this.Animate5=!isElementNotInViewport(this.$refs.logobox5)
    },
    handleCarouselChange(currentIndex) {
      this.currentIndex = currentIndex;
    },
    choosePage(index) {
      if (index >= 0 && index < 3) { // 确保索引在有效范围内
        this.$refs.carousel.setActiveItem(index)
      }
      console.log(this.activeIndex)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
  }
}
</script>

<style scoped>
.describe{
  display: flex;
  flex-direction: column;
  width: 90%;
}
.describe span:nth-child(1){
  font-size: calc(20px + 1.5vw);
  text-align: center;
  margin-bottom: 20px;
  padding-top: 20px;
}
.change-the-text{
  margin-bottom: 20px;
}
.change-the-text-top{
  padding-top: 30px;
}
.custom-indicators{
  text-align: center;
  display: flex;
}
.custom-indicators div{
  font-size: calc(8px + 1vw);
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.custom-indicators * {
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}
.custom-indicators .active{
  color: cornflowerblue!important;
}
.custom-indicators .active::after {
  content: ""; /* 伪元素必须有内容 */
  position: absolute; /* 相对于a元素定位 */
  left: 0; /* 从左边开始 */
  right: 0; /* 到右边结束 */
  bottom: -10px; /* 下划线与文字之间的间隔 */
  height: 4px; /* 下划线的厚度 */
  background-color: cornflowerblue; /* 下划线的颜色 */
  opacity: 1!important;
  transition: max-width 10s ease-in-out; /* 过渡效果 */
}

.img-text-box{
  display: flex;
  align-items: center;
}
.img-text-box img:nth-child(1){
  margin-bottom: 10px;
}
.texts-all-4 div{
  display: flex;
  flex-direction: row;
}
.texts-all-4 span{
  display: flex;
  flex-direction: row;
}
.texts-all-4 img{
  height: 16px;
  width: 16px;
  margin-top: 5px;
  margin-right: 5px;
}
.text-type-1{
  font-weight: bold;
  font-size: calc(16px + 1vw);
}
.text-type-2{
  font-size: calc(10px + 0.8vw);
  margin-bottom: 20px;
}
.text-type-3{
  color: gray;
}

.el-carousel__item{
  width: auto;
}
.have-background-img{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
  width: 100%;
  padding-bottom: 30px;
}

.box-contain-2 {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-left: 5%;
}
.box-contain-2 span:nth-child(2){
  margin-top: 10px;
  color: gray;
  line-height: 2;
  width: 90%;
}

.have-background-color{
  background-color: #f9f9f9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.no-background-color{
  padding-top: 50px;
  padding-bottom: 50px;
}
.texts span:nth-child(1){
  justify-content: center;
  font-size: 30px;
  margin-bottom: 20px;
}
.texts span:nth-child(2){
  font-size: 20px;
}

.benchmark-box img:hover{
  transform: scale(1.1);
}

.hardware-and-software{
  text-align: center;
}

@media (max-width: 768px) {
  .texts-all-4 img{
    margin-top: 0;
  }
  .text-type-2{
    font-size: calc(10px + 0.8vw);
    margin-bottom: 10px;
  }
  .change-img-bottom img{
    margin-bottom: 20px;
  }
  .box-contain-2 span:nth-child(2){
    width: 100%;
  }
  .benchmark-box img{
    width: 100%;
  }
  .texts {
    margin-top: calc(3px + 1vw);
  }
  .texts span:nth-child(1){
    justify-content: center;
    margin-bottom: 0;
  }
  .texts span:nth-child(2){
    padding-bottom: 0;
    font-size: 16px;
  }
  .have-background-img{
    background-image: url("@/assets/IoT_images/软硬结合背景图-移动端.jpg");
    width: 100%;
    padding-bottom: 30px;
  }
}
</style>