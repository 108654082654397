<template>
  <NavbarCombination active-page="solves"></NavbarCombination>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img src="@/assets/products_images/banner背景.jpg" alt="" style="text-align: center;">
    <div>
      <span>
        供应商、客户协同方案
      </span>
      <span>
        统一系统平台，构建高效沟通渠道
      </span>
    </div>
  </div>

  <div class="container module describe">
    <span>
      方案概述
    </span>
    <span class="middle-text">
      供应商协同（SRM）是指供应商上下游企业之间建立紧密合作的关系，通过信息共享、资源整合、业务协同等方式，实现供应链的全面管理和优化；客户协同（CRM）是现代企业销售跟踪和客户管理的核心工具，帮助企业通过高效、智能化的数据管理和技术手段，全面提升客户体验，提高运营效率，并驱动企业持续增长。
    </span>
  </div>

  <div class="have-background-img">
    <div class="container">
      <img src="@/assets/solve_images/供应商、客户协同.png">
    </div>

  </div>

  <div class="container module-box">
    <div class="module-title-box">
      <span class="module-title">产品功能及亮点</span>
    </div>
    <div class="row">
      <div class="col-md-6 col-12 img-box">
        <img src="@/assets/home_images/module.png">
      </div>
      <div class="col-md-6 col-12 texts-all-box">
        <div class="row ">
          <div v-for="(content,index) in all_describes" :key="index" class="col-12 drop-down-all">
            <div @click="chooseDropdown(index)" class="drop-down-box" style="cursor: pointer;">
              <img src="@/assets/icons/chevron-right.svg" :class="{'down-nav': visible && (currentIndex === index)}" class="click-nav">
              <span class="drop-down-text1">&nbsp;{{ content.name }}</span>
            </div>
            <transition name="fade" >
              <span v-show="visible && (currentIndex===index)" class="drop-down-text2" style="overflow: hidden;height: auto;">{{ content.text }}</span>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};

export default {
  name: "ProductsCenter",
  components: { RightNavbar, NavbarCombination},
  data(){
    return{
      visible: true,
      currentIndex: 10,
      Animate1: false,
      isPhone: false,
      all_describes:[
        {
          name:'优化供应链管理',
          text:'帮助企业实现对供应商的全面管理，包括供应商的选择、合同管理，从而优化供应链管理，提高采购效率和降低采购成本'
        },
        {
          name:'全面的客户管理',
          text:'建立全面的客户信息，实现与客户间的信息共享、资源整合和协作'
        },
        {
          name:'业务流程优化',
          text:'帮助企业和供应商、客户之间实现实时沟通、协同合作，减少重复录入和人为错误，加速业务流程处理'
        },
        {
          name:'数据分析与决策支持',
          text:'帮助企业深入了解供应商的表现和趋势，为企业提供决策支持，优化供应链管理和采购策略，提高企业的竞争力和市场占有率'
        },
      ],
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
    chooseDropdown(index){
      if (this.currentIndex !== index){
        this.currentIndex = index;
        if (!this.visible){
          this.visible = !this.visible;
        }
      }else {
        this.visible = !this.visible;
      }
    },

  },
}
</script>

<style scoped>
/* 有背景图片 */
.have-background-img{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .show-img div span:nth-child(1){
    font-size: 31px;
  }
}
</style>