<template>
  <NavbarCombination :activePage="'CooperateCenter'"/>
  <RightNavbar/>
  <div class="top-img box-offset">
    <img v-if="isPhone" src="@/assets/home_images/合作中心banner-移动端.jpg" alt="">
    <img v-else src="@/assets/home_images/合作中心banner-pc.jpg" alt="">
    <div class="img-inner-text">
      <span>
        生态合作共赢
      </span>
      <span>
        与易捷一起探索行业发展新方式，互利双赢
      </span>
      <div>
        <button class="become-partner">
          成为合作伙伴
        </button>
      </div>
    </div>
  </div>

  <div class="have-background-color">
    <div class="container describe">
      <span>为什么加入我们</span>
      <span>与生态圈一起成长，共同推动行业的升级发展，创造更大的价值</span>
      <img v-if="isPhone" src="@/assets/home_images/为什么加入我们-移动端.png" class="img-display">
      <img v-else src="@/assets/home_images/为什么加入我们-字.png" class="img-display">
    </div>
  </div>
  <div v-if="isPhone" class="have-background-img-mobile" style="padding-bottom: 20px;">
    <div class="white-title">
      <span>合作流程</span>
    </div>
    <div class="cooperation-all-box">
      <div class="cooperation-out-box">
        <div class="cooperation-box">
          <div class="point-with-line">
            <div class="point"></div>
            <div class="line"></div>
          </div>
          <div class="right-texts">
            <span class="large-text">&nbsp;&nbsp;&nbsp;1、</span>
            <span class="large-text">提交申请</span>
          </div>
        </div>
      </div>
      <div class="cooperation-out-box">
        <div class="cooperation-box">
          <div class="point-with-line">
            <div class="point"></div>
            <div class="line"></div>
          </div>
          <div class="right-texts">
            <span class="large-text">&nbsp;&nbsp;&nbsp;2、</span>
            <span class="large-text">合作洽谈</span>
          </div>
        </div>
      </div>
      <div class="cooperation-out-box">
        <div class="cooperation-box">
          <div class="point-with-line">
            <div class="point"></div>
            <div class="line"></div>
          </div>
          <div class="right-texts">
            <span class="large-text">&nbsp;&nbsp;&nbsp;3、</span>
            <span class="large-text">签署协议</span>
          </div>
        </div>
      </div>
      <div class="cooperation-out-box">
        <div class="cooperation-box">
          <div class="point-with-line">
            <div class="point"></div>
            <div class="line"></div>
          </div>
          <div class="right-texts">
            <span class="large-text">&nbsp;&nbsp;&nbsp;4、</span>
            <span class="large-text">赋能培训</span>
          </div>
        </div>
      </div>
      <div class="cooperation-out-box">
        <div class="cooperation-box">
          <div class="point-with-line">
            <div class="point"></div>
            <div class="line"></div>
          </div>
          <div class="right-texts">
            <span class="large-text">&nbsp;&nbsp;&nbsp;5、</span>
            <span class="large-text">业务流程</span>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div v-else class="have-background-img">
    <div class="white-title">
      <span>合作流程</span>
    </div>
    <div class="collaboration-process">
      <div class="col-1"></div>
      <div class="col-2">
        <span class="large-text">1、</span>
        <div class="point-line-box">
          <div class="point"></div>
          <div class="line"></div>
        </div>
        <div class="vertical-text-box">
          <span class="large-text">提交申请</span>
        </div>
      </div>
      <div class="col-2">
        <span class="large-text">2、</span>
        <div class="point-line-box">
          <div class="point"></div>
          <div class="line"></div>
        </div>
        <div class="vertical-text-box">
          <span class="large-text">合作洽谈</span>
        </div>
      </div>
      <div class="col-2">
        <span class="large-text">3、</span>
        <div class="point-line-box">
          <div class="point"></div>
          <div class="line"></div>
        </div>
        <div class="vertical-text-box">
          <span class="large-text">签署协议</span>
        </div>
      </div>
      <div class="col-2">
        <span class="large-text">4、</span>
        <div class="point-line-box">
          <div class="point"></div>
          <div class="line"></div>
        </div>
        <div class="vertical-text-box">
          <span class="large-text">赋能培训</span>
        </div>
      </div>
      <div class="col-2">
        <span class="large-text">5、</span>
        <div class="point-line-box">
          <div class="point"></div>
          <div class="line"></div>
        </div>
        <div class="vertical-text-box">
          <span class="large-text">业务流程</span>
        </div>
      </div>
      <div class="col-1"></div>
    </div>


  </div>

  <div class="module5">
    <div class="container module new-module">
      <div class="row">
        <div class="title">
          <div class="info_list">
            <h1>合作伙伴&nbsp;</h1>
            <div>
              <h6>PARTNERS&nbsp;</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row partners-imgs">
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/海康威视.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/苏泊尔.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/老板电器.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/科大讯飞.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/火星人.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/中石化.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/百世.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/远信.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/海柔创新.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/海康机器人.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/用友.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/森哥.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/兰州硅材.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/四季沐歌.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/美多.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/默飓.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/美宁.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/戈享.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/欧帝.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/永升.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/悠贝.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/卡梦帝.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/健达通.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/凯马精工.jpg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data(){
    return {
      screenWidthSize: window.innerWidth,
      isPhone: false
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  methods:{
    DownLoadFile(filename,fileUrl){
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', filename);
      link.click();
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },

  }
}
</script>

<style scoped>
/* 开头的第一张图片样式，包括文字 */
.top-img{
  position: relative;
}
.top-img img{
  height: 60vh;
  width: 100%;
}
.img-inner-text{
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 8%;
  bottom: 40%;
  right: 8%;
  text-align: center;
  color: dodgerblue;
}
.top-img div span:nth-child(1){
  font-weight: bold;
  font-size: calc(30px + 1vw);
}
.top-img div span:nth-child(2){
  font-size: 1.5rem;
}
.become-partner{
  margin-top: 10px;
  background-color: dodgerblue;
  color: white;
  border: none;
  height: 40px;
  width: 140px;
}

.have-background-color{
  background-color: #f9f9f9;
  position: relative;
}
.describe span:nth-child(2){
  text-align: center;
}
.img-display{
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}
.have-background-img{
  position: relative;
  background-image: url("@/assets/home_images/优势和底部的背景图.jpg");
  width: 100vw;
  height: auto;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.col-2{
  padding: 0;
}
.white-title{
  color: white;
  font-size: calc(20px + 1.5vw);
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.point-line-box{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}
.point {
  width: 22px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
}
.line {
  background-color: white; /* 背景透明 */
  border-bottom: 2px solid white; /* 创建一个黑色的下边框作为线 */
  width: 100%;
  margin: auto;
  height: 2px;
}
.collaboration-process{
  display: flex;
  flex-direction: row;
  color: white;
  position: relative;
  padding-bottom: 50px;
}
.describe span:nth-child(1){
  padding-top: 60px;
}
@media (max-width: 768px) {
  .have-background-img-mobile{
    background-image: url("@/assets/solve_images/背景-移动端.jpg");
  }
  .point-with-line {
    position: relative; /* 设置相对定位，以便线可以相对于点进行定位 */
    display: inline-block; /* 或者其他您需要的显示方式 */
    /* 您可以根据需要添加边距、内边距等 */
  }

  .point {
    width: 16px; /* 点的宽度 */
    height: 16px; /* 点的高度 */
    background-color: white; /* 点的颜色 */
    border-radius: 50%; /* 使点变成圆形 */
    /* 如果需要，可以添加更多样式来调整点的外观 */
  }

  .line {
    position: absolute; /* 绝对定位，相对于最近的已定位祖先元素（这里是.point-with-line） */
    top: 50%; /* 使线的顶部位于点的中心 */
    left: 50%; /* 使线的左侧位于点的中心 */
    transform: translate(-50%, 0); /* 向上和向左移动线的一半宽度和高度，使其中心与点对齐 */
    width: 2px; /* 线的宽度 */
    height: 50px; /* 线的长度，根据需要调整 */
    background-color: white; /* 线的颜色 */
    /* 如果需要，可以添加更多样式来调整线的外观，如边框、阴影等 */
  }
  .right-texts{
    color: white;
    text-align: right;
    padding-top: 0;
    display: contents;
  }
  .cooperation-box{
    max-width: 40%;
    margin: auto;
    padding-bottom: 30px;
  }
  .cooperation-out-box{
    justify-content: center;
  }
  .cooperation-all-box{
    margin-bottom: 20px;
  }
  .vertical-text-box{
    max-width: 20px;
  }
  .img-inner-text{
    bottom: 30%;
  }
  .serve-process-box img{
    height: 80px;
    width: 80px;
  }
  .show-img div{
    bottom: 30%;
  }
  .describe span:nth-child(2){
    text-align: initial;
  }
}
</style>
