<template>
  <NavbarCombination :activePage="'solves'"/>
  <RightNavbar/>
  <div class="top-img box-offset">
    <img src="@/assets/solve_images/center_banner背景.jpg" alt="">
    <div class="img-inner-text">
      <span>
        助力企业,共创未来
      </span>
      <span>
        数字技术与精益生产深度融合,助力企业打造高效运营的数字化工厂
      </span>
      <div>
        <button class="become-partner">
          成为合作伙伴
        </button>
      </div>
    </div>
  </div>

  <div class="levitation-box have-background-img1" :class="Animate1 ? 'logobox myanimation' : 'logobox'" ref="logobox1">
    <div class="container">
      <div class="row">
        <div class="col-md-4 levitation" v-for="(texts,index) in content" :key="index">
          <div class="levitation-text">
            <span>{{ texts.text1 }}</span>
            <span>{{ texts.text2 }}</span>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="have-background-img2">
    <div class="container module-title">
      <span>解决方案</span>
      <span>深度赋能行业，助力企业数字化升级</span>
    </div>
    <div class="container ">
      <div class="row">
        <div class="col-md-6 col-xs-12 col-lg-3 every-part-box" v-for="(part,index) in modules" :key="index" @click="goToPart(part.url)" :style="(index === 6) || (index === 7) ? 'display:none' : ''">
          <span class="every-part-text1 largest-text" >{{ part.part_name }}<span class="hover-show">&nbsp;></span></span>
          <span class="every-part-text2 small-text">{{ part.introduce }}</span>
          <div class="row">
            <div class="col-4 every-part-text3-box" v-for="(detail,index2) in part.details" :key="index2">
              <div class="every-part-text3">{{ detail }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="module2">
    <div class="container module-title">
      <span>方案优势</span>
    </div>
    <div class="container" :class="Animate2 ? 'logobox myanimation' : 'logobox'" ref="logobox2">
      <div class="row">
        <div class="blocks5 col-md col-12" v-for="(list,index) in blocks" :key="index">
          <img :src="list.imgurl">
          <span class="largest-text">
            {{ list.summary }}
          </span>
          <span class="middle-text">
            {{ list.describe }}
          </span>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  }
};

export default {
  name: "SolveCenter",
  data(){
    return {
      Animate1: false,
      Animate2: false,
      modules:[
        {
          part_name:'零部件溯源方案',
          url:'/solve/ComponentsParts',
          introduce:'全面追溯零部件的来源，生产过程及流向',
          details:['供应商管理','物料管理','一物一码','制造过程','原材料批次','出入库流向']
        },
        {
          part_name:'质检溯源方案',
        url:'/solve/QualityProgram',
          introduce:'确保产品质量，维护消费者权益',
          details:['物料质检','工序质检','一物一码','制造过程','质检结果']
        },
        {
          part_name:'物料齐套率方案',
          url:'/solve/CompleteParts',
          introduce:'确保生产质量和生产效率的重要因素之一',
          details:['物料清单','物料需求','物料使用情况','零部件管理','供应商管理','仓库优化']
        },
        {
          part_name:'客户/供应商协同方案',
          url:'/solve/CustomerSuppliers',
          introduce:'企业与供应商紧密合作',
          details:['供应商管理','客户管理','价目表','商品数据关联','协同合作']
        },
        {
          part_name:'产仓一体化方案',
          url:'/solve/BirthSilos',
          introduce:'支持多仓库精细化管理，策略灵活配置',
          details:['自动化生产','柔性部署','智能AGV','信息化管理','数智化升级']
        },
        {
          part_name:'仓配一体化方案',
          url:'/solve/OneDistribution',
          introduce:'仓库与配送之间的无缝对接和高效运作',
          details:['订单管理','出入库管理','智能分拣','智能配送','智能AGV']
        },
        {
          part_name:'客户定制方案',
          url:'/solve/CustomerSpecific',
          introduce:'个性化的系统解决方案',
          details:['专属打造','二次开发','服务至上','部署培训']
        },
        {
          part_name:'软硬件结合方案',
          url:'/solve/SoftHard',
          introduce:'满足快速多样的管理要求',
          details:['专属打造','可独立可互联','设备联动','数据集成','灵活部署']
        },
      ],
      blocks:[
        {
          imgurl:require('@/assets/icons/专业.png'),
          summary:'专业',
          describe:'专注于软件开发与服务，自主研发，创新实力强大'
        },
        {
          imgurl:require('@/assets/icons/安全.png'),
          summary:'安全',
          describe:'先进技术架构、安全体系和权限控制'
        },
        {
          imgurl:require('@/assets/icons/稳定.png'),
          summary:'稳定',
          describe:'不同行业，不同地区，不同用户，每天都在同步使用我们的软件'
        },
        {
          imgurl:require('@/assets/icons/易用.png'),
          summary:'易用',
          describe:'按需求定制，匹配企业管理，易于理解，上手更快'
        },
        {
          imgurl:require('@/assets/icons/省时.png'),
          summary:'省时',
          describe:'无需长时间运行测试，快速投入应用'
        },
        {
          imgurl:require('@/assets/icons/省钱.png'),
          summary:'省钱',
          describe:'无需支付大量人员工资、设备物资费用，无需专人或团队维护'
        },
      ],
      content:[
        {
          text1:'物联化',
          text2:'软硬互联,设备管控'
        },
        {
          text1:'弹性化',
          text2:'优化流程，按需配置'
        },
        {
          text1:'透明化',
          text2:'各环相通，进度透明'
        },
      ]
    }
  },
  mounted() {
    // 监听滚动事件
    this.Animate1 = !isElementNotInViewport(this.$refs.logobox1)
    window.addEventListener("scroll", this.scrollToTop);
  },
  methods: {
    goToPart(targetUrl){
      window.location.href = targetUrl;
    },
    // 滚动事件
    scrollToTop() {
      this.Animate2 = !isElementNotInViewport(this.$refs.logobox2)
    },
  }
}
</script>

<style scoped>
.levitation-box{
  position: relative;
}
.levitation{
  position: relative;
  margin-top: -40px;
}
.levitation-text{
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-content: center;
  text-align: center;
  height: calc(60px + 7vw);
  border-radius: calc(3px + 0.2vw);
  box-shadow: darkgrey 0px 0px 10px;
}
.levitation-text span:nth-child(1){
  font-size: calc(16px + 1.2vw);
  margin-bottom: 8px;
}
.levitation-text span:nth-child(2){
  font-size: calc(10px + 0.5vw);
}

/* 开头的第一张图片样式，包括文字 */
.top-img{
  position: relative;
}
.top-img img{
  height: 60vh;
  width: 100%;
}
.img-inner-text{
  position: absolute;
  display: flex;
  flex-direction: column;
  color: white;
  left: 8%;
  bottom: 40%;
  right: 8%;
}
.top-img div span:nth-child(1){
  font-weight: bold;
  font-size: calc(30px + 1vw);
}
.top-img div span:nth-child(2){
  font-size: 1.5rem;
}
.become-partner{
  margin-top: 10px;
  background-color: dodgerblue;
  color: white;
  border: none;
  height: 40px;
  width: 140px;
}
.have-background-img1{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
}
.have-background-img2{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
  padding-bottom: 80px;
}
.module-title{
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 50px;
  margin-bottom: 30px;
}
.module-title span:nth-child(1){
  font-size: calc(20px + 1.5vw);
  /*position: relative;*/
}
.module-title span:nth-child(2){
  font-size: calc(16px + 0.5vw);
}
.every-part-box{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.every-part-box:hover{
  cursor: pointer;
}
.hover-show{
  display: none;
}
.every-part-box:hover .every-part-text1,.every-part-box:hover .hover-show{
    color: dodgerblue;
    display: inline;
}
.every-part-text1{
  font-weight: bold;
  padding-left: 0;
}
.every-part-text2{
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0;
}
.every-part-text3-box{
  padding: 0;
}
.every-part-text3{
  background-color: white;
  font-size: 12px;
  text-align: center;
  color: gray;
  border: black 1px solid;
  margin: 5px;
  padding: 5px;
  height: 30px;
}

.blocks5{
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-left: 8px;
  height: 400px;
  box-shadow: rgba(211,211,211,0.5) 0 0 16px;
  margin-bottom: 50px;
}

.blocks5 img{
  margin-top: 20%;
  width: calc(1.5vw + 20px);
  height: calc(1.5vw + 20px);
  margin-bottom: 10%;
}
.blocks5>span:nth-child(2) {
  font-weight: bold;
  margin-bottom: 20%;
}

@media (max-width: 768px){
  .img-inner-text{
    bottom: 30%;
  }
  .blocks5{
    height: auto;
    margin:5px 5% 10px 5%;
    width: 90%;
    box-shadow: rgba(211,211,211,0.5) 0 0 20px;
  }
  .blocks5 img{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .blocks5>span:nth-child(2){
    margin-bottom: 10px;
  }
  .blocks5>span:nth-child(3){
    margin-bottom: 10px;
  }
  .levitation{
    margin-top: 0px;
  }
  .levitation-text{
    position: relative;
    margin-top: 20px;
  }
}
</style>