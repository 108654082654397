<template>
  <NavbarCombination :activePage="'home'"/>
  <RightNavbar/>
  <div class="carousel-box box-offset">
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
      <ol class="carousel-indicators">
        <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></li>
        <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="@/assets/home_images/首页banner背景图.png" class="d-block w-100" alt="...">
          <div class="carousel-captions">
            <h1>易捷，让企业轻装上阵！</h1>
            <h4>让每个个体也能拥抱整个行业！</h4>
          </div>
        </div>
        <div class="carousel-item">
          <img src="@/assets/home_images/轮播图2.png" class="d-block w-100" alt="...">
          <div class="carousel-captions">
            <h1>一站式系统解决方案</h1>
            <h4>多系统支持原生互联，一键部署，数据互通</h4>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </a>
    </div>
  </div>

  <div class="container module">
    <div class="row change-this-top">
      <div class="col-md-6">
        <div class="title">
          <div class="info_list" >
            <h1>关于易捷&nbsp;</h1>
            <div>
              <h6>ABOUT&nbsp;YIJIE</h6>
            </div>
          </div>
        </div>
        <div :class="screenSize">
          <h2 class="largest-text">软件与信息技术的服务提供商</h2>
          <p class="small-text">
            &emsp; &emsp;绍兴易捷信息技术有限公司注册地和运营中心位于中国厨具之都浙江嵊州，是一家专注于互联网技术开发和运营的创新型企业，企业管理软件开发商和服务提供商。秉承“用户至上，服务用心”的服务宗旨，坚持“提升价值，成就客户”的经营理念，致力于成为管理软件技术研发、系统集成的改进创新者、一站式系统解决方案的服务者
            <br>
            <br>
            &emsp; &emsp;易捷信息主要从事管理软件的研发、设计并提供相关服务，产品主要包括ERP系统、MES系统、E-WES系统等。具备为客户提供多种应用场景下的定制化、专业化和智能化的系统软件和技术服务能力，充分满足客户需求
          </p>
        </div>
        <router-link to="/aboutUs" :style="isPhone ? isCenter : {}" class="none-active">
          <button class="button-more">更多内容 ></button>
        </router-link>
      </div>
      <div class="col-md-6" style="display: grid;align-content: center;">
        <img src="@/assets/home_images/科创中心.jpg" alt="易捷信息" class="module1-img"/>
      </div>
    </div>

  </div>

  <div class="module2" :class="Animate1 ? 'logobox myanimation' : 'logobox'" ref="logobox1">
    <div class="container module new-module">
      <div class="row">
        <div class="title">
            <div class="info_list">
                <h1>产品简介及优势&nbsp;</h1>
              <div>
                <h6>PRODUCT&amp;CHARACTERISTIC</h6>
              </div>
            </div>
          </div>
        <div class="col-md-1"></div>
      </div>
      <div class="row" style="padding: 20px 5px">
        <router-link to="/product/ERP" class="col-md-3 col-6 prod-div">
          <div :style="backgroundProd('erp.jpg')">
            <div><h5><span>ERP</span>企业资源计划</h5></div>
          </div>
        </router-link>
        <router-link to="/product/MES" class="col-md-3 col-6 prod-div ">
          <div :style="backgroundProd('MES.jpg')">
            <div><h5><span>MES</span>制造执行系统</h5></div>
          </div>
        </router-link>
        <router-link to="/product/WES" class="col-md-6 col-6 prod-div">
          <div :style="backgroundProd('E-WES.jpg')" style="background-size: 100%;">
            <div><h5><span>E-WES</span>仓库执行系统</h5></div>
          </div>
        </router-link>

        <router-link to="/product/ASMS" class="col-md-3 col-6 prod-div">
          <div :style="backgroundProd('售后.jpg')">
            <div><h5><span>E-ASMS</span>售后管理</h5></div>
          </div>
        </router-link>
        <router-link to="/product/IotEdge" class="col-md-6 col-6 prod-div">
          <div :style="backgroundProd('IOT-Edge.jpg')">
            <div><h5><span>IOT-Edge</span>物联网边缘计算服务</h5></div>
          </div>
        </router-link>
        <router-link to="/product/ObjectCode" class="col-md-3 col-6 prod-div">
          <div :style="backgroundProd('一物一码.jpg')">
            <div><h5><span>一物一码</span></h5></div>
          </div>
        </router-link>

        <router-link to="/product/MobileApp" class="col-md-6 col-6 prod-div">
          <div v-if="isPhone"  :style="backgroundProd('移动端应用.jpg')">
            <div><h5><span>移动端应用</span></h5></div>
          </div>
          <div v-else  :style="backgroundProd('移动端应用.jpg')" style="background-size: 100%;">
            <div><h5><span>移动端应用</span></h5></div>
          </div>
        </router-link>
        <router-link to="/product/HardwareHome" class="col-md-6 col-6 prod-div">
          <div :style="backgroundProd('智能装备.jpg')">
            <div><h5><span>智能装备</span></h5></div>
          </div>
        </router-link>
      </div>
    </div>
  </div>

  <div class="module3" >
    <div class="container" :class="Animate2 ? 'logobox myanimation' : 'logobox'" ref="logobox2">
      <div class="row padding-to-0">
        <div class="col-lg-3 col-6 four-box">
          <div class="large-text">
            一体化<br>协同管理
          </div>
          <div class="small-text">
            一套软件解决所有管理问题，<br>
            一个平台兼容所有数据，<br>
            一次录入实现全模块可用，
          </div>
        </div>
        <div class="col-lg-3 col-6 four-box">
          <div class="large-text ">
              系统<br>可独立可互联
          </div>
          <div class="small-text">
            多套系统可独立使用，<br>
            也可多套并行使用，<br>
            实现流程、数据、软件等无缝集成
          </div>
        </div>
        <div class="col-lg-3 col-6 four-box">
          <div class="large-text ">
              全方位<br>升级拓展
          </div>
          <div class="small-text">
            按需部署，持续发展，无缝对接<br>
            个性化定制
          </div>
        </div>
        <div class="col-lg-3 col-6 four-box">
          <div class="large-text ">
              易安装操作<br>易维护
          </div>
          <div class="small-text">
            无需安装客户端，简洁页面布局<br>
            吻合用户操作习惯，系统简易维护
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="module4">
    <div class="container module new-module">
      <div class="row">
        <div class="title">
          <div class="info_list">
            <h1>标杆案例&nbsp;</h1>
            <div>
              <h6>BENCHMARK&nbsp;CASE</h6>
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <div class="three-imgs">
        <div class="row">
          <a class="col-md-4 col-xs-1">
            <div class="benchmark-box">
              <img src="@/assets/home_images/美多电器.jpg" alt="erp">
              <div class="texts" :class="Animate3 ? 'logobox myanimation' : 'logobox'" ref="logobox3">
                <span>美多电器</span>
                <span class="small-text">浙江美多电器有限公司始创于1989年，于1998年正式进入厨卫电器行业，是中国厨卫电器行业的佼佼者和集成灶产品的行业引领者，已累计为中国上干万家庭厨房提供解决方案，是国内厨电行业的知名制造商。</span>
                <span>实现产品一机一码、库存精确管理；MES系统实现数据分析平台、提供多维度实时......</span>
              </div>
            </div>
          </a>
          <a class="col-md-4 col-xs-1">
            <div class="benchmark-box">
              <img src="@/assets/home_images/远信工业.jpg" alt="erp">
              <div class="texts" :class="Animate4 ? 'logobox myanimation' : 'logobox'" ref="logobox4">
                <span>远信工业</span>
                <span class="small-text">远信工业股份有限公司是中国纺织机械、印染机械、机械配件行业的领先企业，创立于2010年。专注于提供高品质的产品和服务。以远见卓识、创新发展为使命，立志成为行业领导者，塑造了远信品质和品牌，迅速成为高端染整设备的专业制造商。</span>
                <span>实现与其他系统及设备对接，实现信息自动传递和处理，提高业务响应速度......</span>
              </div>
            </div>
          </a>
          <a class="col-md-4 col-xs-1">
            <div class="benchmark-box">
              <img src="@/assets/home_images/埃肯硅材料.jpg" alt="erp">
              <div class="texts" :class="Animate5 ? 'logobox myanimation' : 'logobox'" ref="logobox5">
                <span>中化埃肯</span>
                <span class="small-text">埃肯硅材料兰州有限公司是中国中化控股有限公司全资子公司，主要生产高端化学级工业硅和特种硅材料，是我国工艺技术领先的行业标杆企业，正在向着世界最先进的工业硅企业大步奋进。</span>
                <span>实现与上层系统结合，不同分区采用AGV智能搬运和系统引导人工叉车扫码搬运</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="module5">
    <div class="container module new-module">
      <div class="row">
        <div class="title">
          <div class="info_list">
            <h1>合作伙伴&nbsp;</h1>
            <div>
              <h6>PARTNERS&nbsp;</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row partners-imgs">
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/海康威视.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/苏泊尔.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/老板电器.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/科大讯飞.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/火星人.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/中石化.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/百世.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/远信.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/海柔创新.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/海康机器人.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/用友.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/森哥.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/兰州硅材.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/四季沐歌.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/美多.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/默飓.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/美宁.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/戈享.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/欧帝.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/永升.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/悠贝.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/卡梦帝.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/健达通.jpg">
        </div>
        <div class="col-md-2 col-4">
          <img src="@/assets/partners/凯马精工.jpg">
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};
export default {
  components: {
    NavbarCombination,
    RightNavbar,
  },
  data() {
    return {
      Animate1: false,
      Animate2: false,
      Animate3: false,
      Animate4: false,
      Animate5: false,
      screenWidthSize: window.innerWidth,
      isPhone: false,
      isCenter:{
        display: 'grid',
        justifyContent: 'center',
      }
    }
  },
  computed: {
    // 使用计算属性来确定屏幕类
    screenSize() {
      if (this.screenWidthSize < 768) {
        return 'mobile-screen';
      } else if (this.screenWidthSize >= 768 && this.screenWidthSize < 1200) {
        return 'tablet-screen';
      } else {
        return 'desktop-screen';
      }
    },
  },

  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
      this.Animate2=!isElementNotInViewport(this.$refs.logobox2)
      this.Animate3=!isElementNotInViewport(this.$refs.logobox3)
      this.Animate4=!isElementNotInViewport(this.$refs.logobox4)
      this.Animate5=!isElementNotInViewport(this.$refs.logobox5)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
    backgroundProd(img){
      let imgUrl = require('@/assets/home_images/'+img)
      return {
        // 使用计算属性来动态生成内联样式
        backgroundImage: `url(${imgUrl})`,
        height:"100%",
        backgroundSize: 'auto 100%', // 示例：背景图覆盖
        backgroundPosition: 'center' // 示例：背景图居中
      };
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener('scroll',this.scrollToTop);
    next();
  },
  beforeUnmount() {
    // 移除事件监听器
    window.removeEventListener('resize', this.updateScreenSize);
  },
}



</script>

<style scoped>
@import '@/assets/css/Home.css';
</style>
