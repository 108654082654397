import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/home/HomeVue.vue'
import ProductsCenter from "@/views/products/ProductsCenter.vue";
import Product_ERP from './views/products/ERPPlan.vue'
import MESSystem from "@/views/products/MESSystem.vue";
import WESSystem from "@/views/products/WESSystem.vue";
import ASMSSystem from "@/views/products/ASMSSystem.vue";
import IoTEdge from "@/views/products/IoTEdge.vue";
import MobileApp from "@/views/products/MobileApp.vue";
import ObjectCode from "@/views/products/ObjectCode.vue";
import HardwareHome from "@/views/products/HardwareHome.vue";

import SolveCenter from "@/views/solve/SolveCenter.vue";
import CustomerSuppliers from "@/views/solve/CustomerSuppliers.vue";
import ComponentsParts from "@/views/solve/ComponentsParts.vue";
import QualityProgram from "@/views/solve/QualityProgram.vue";
import BirthSilos from "@/views/solve/BirthSilos.vue";
import CompleteParts from "@/views/solve/CompleteParts.vue";
import CustomerSpecific from "@/views/solve/CustomerSpecific .vue";
import OneDistribution from "@/views/solve/OneDistribution.vue";
import SoftHard from "@/views/solve/SoftHard.vue";

import AboutUs from "@/views/home/AboutUs.vue";

import ServeCenter from "@/views/home/ServeCenter.vue";

import CooperateCenter from "@/views/home/CooperateCenter.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/products',
        name: 'ProductsCenter',
        component: ProductsCenter
    },
    {
        path: '/product/ERP',
        name: 'Product_ERP',
        component: Product_ERP
    },
    {
        path: '/product/MES',
        name: 'Product_MES',
        component: MESSystem
    },
    {
        path: '/product/WES',
        name: 'Product_WES',
        component: WESSystem
    },
    {
        path: '/product/ASMS',
        name: 'Product_ASMS',
        component: ASMSSystem
    },
    {
        path: '/product/IoTEdge',
        name: 'Product_IoTEdge',
        component: IoTEdge
    },
    {
        path: '/product/MobileApp',
        name: 'Product_MobileApp',
        component: MobileApp
    },
    {
        path: '/product/ObjectCode',
        name: 'Product_ObjectCode',
        component: ObjectCode
    },
    {
        path: '/product/HardwareHome',
        name: 'Product_HardwareHome',
        component: HardwareHome
    },
    {
        path: '/solves',
        name: 'SolveCenter',
        component: SolveCenter
    },
    {
        path: '/solve/CustomerSuppliers',
        name: 'CustomerSuppliers',
        component: CustomerSuppliers
    },
    {
        path: '/solve/BirthSilos',
        name: 'BirthSilos',
        component: BirthSilos
    },
    {
        path: '/solve/CompleteParts',
        name: 'CompleteParts',
        component: CompleteParts
    },
    {
        path: '/solve/CustomerSpecific',
        name: 'CustomerSpecific',
        component: CustomerSpecific
    },
    {
        path: '/solve/OneDistribution',
        name: 'OneDistribution',
        component: OneDistribution
    },
    {
        path: '/solve/SoftHard',
        name: 'SoftHard',
        component: SoftHard
    },
    {
        path: '/solve/QualityProgram',
        name: 'QualityProgram',
        component: QualityProgram
    },
    {
        path: '/solve/ComponentsParts',
        name: 'ComponentsParts',
        component: ComponentsParts
    },
    {
        path: '/AboutUs',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/ServeCenter',
        name: 'ServeCenter',
        component: ServeCenter
    },
    {
        path: '/CooperateCenter',
        name: 'CooperateCenter',
        component: CooperateCenter
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
    // 在路由变化之前，确保页面滚动到顶部
    window.scrollTo(0, 0);
    next();
});
export default router
