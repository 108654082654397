<template>
  <NavbarCombination active-page="solves"></NavbarCombination>
  <RightNavbar/>
  <div class="show-img box-offset">
    <img src="@/assets/products_images/banner背景.jpg" alt="" style="text-align: center;">
    <div>
      <span>
        物料齐套率方案
      </span>
      <span>
        落地各行业数字化转型升级的战略目标和价值，共创企业服务生态未来
      </span>
    </div>
  </div>

  <div class="container module describe">
    <span>
      方案概述
    </span>
    <span class="middle-text">
      企业生产物料齐套管理水平是影响库存、交付、成本等的关键指标，是确保生产质量和生产效率的重要因素之一，因此，在离散型制造企业进行生产之前，做好物料齐套管理是非常关键的，包括委外加工件、采购件、供应商提供物料等零部件配齐套后，生产才能高效地进行。易捷信息为企业提供一个操作简便、决策智能化的物料齐套率管理系统，通过该系统，企业可以对产品从采购物料到生产排产等每一个环节进行科学计算，保证企业高效、准确、灵活的运转，提高生产效率，节约成本并且优化管理。
    </span>
  </div>

  <div class="have-background-img">
    <div class="container">
      <img src="@/assets/solve_images/物料齐套.png">
    </div>

  </div>

  <div class="container module-box">
    <div class="module-title-box">
      <span class="module-title">产品功能及亮点</span>
    </div>
    <div class="row">
      <div class="col-md-6 col-12 img-box">
        <img src="@/assets/home_images/module.png">
      </div>
      <div class="col-md-6 col-12 texts-all-box">
        <div class="row ">
          <div v-for="(content,index) in all_describes" :key="index" class="col-12 drop-down-all">
            <div @click="chooseDropdown(index)" class="drop-down-box" style="cursor: pointer;">
              <img src="@/assets/icons/chevron-right.svg" :class="{'down-nav': visible && (currentIndex === index)}" class="click-nav">
              <span class="drop-down-text1">&nbsp;{{ content.name }}</span>
            </div>
            <transition name="fade" >
              <span v-show="visible && (currentIndex===index)" class="drop-down-text2" style="overflow: hidden;height: auto;">{{ content.text }}</span>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarCombination from "@/components/NavbarCombination.vue";
import RightNavbar from "@/components/RightNavbar.vue";

export const isElementNotInViewport = function(el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >=
        (window.innerHeight || document.documentElement.clientHeight)
        // || rect.bottom <= 0
    );
  }
};

export default {
  name: "ProductsCenter",
  components: { RightNavbar, NavbarCombination},
  data(){
    return{
      visible: true,
      currentIndex: 10,
      Animate1: false,
      isPhone: false,
      all_describes:[
        {
          name:'精确物料需求计划（MRP）',
          text:'使用MRP计算来分析生产需求，生成准确的物料采购和生产计划。并且，定期审查和更新物料清单（BOM）,确保信息的准确性和及时性'
        },
        {
          name:'优化库存管理',
          text:'保持适当的安全库存水平，采用先进的库存管理技术，如库存周转率分析等，避免过多或过少的库存，优化库存结构，以应对需求波动和供应链不确定性'
        },
        {
          name:'科学计划排程',
          text:'接收到生产任务，能快速预测出交期，能够按照库存物料计算出可生产任务量；将资源齐套、设备使用、生产班次等数据信息进行实时监控更新，合理安排生产任务，确保按时交期'
        },
        {
          name:'提升质量管理',
          text:'实施严格的质量控制流程，与供应商共同进行质量改进活动，确保所有物料和组合符合质量标准，提高供应链的整体质量水平。'
        },
      ],
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.scrollToTop);
    this.updateScreenSize(); // 初始设置屏幕宽度
    // 使用箭头函数来保持 this 的上下文
    window.addEventListener('resize', this.updateScreenSize);
  },
  methods: {
    // 滚动事件
    scrollToTop() {
      this.Animate1=!isElementNotInViewport(this.$refs.logobox1)
    },
    updateScreenSize() {
      // 更新屏幕宽度
      this.screenWidthSize = window.innerWidth;
      if(this.screenWidthSize <= 768){
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }
    },
    chooseDropdown(index){
      if (this.currentIndex !== index){
        this.currentIndex = index;
        if (!this.visible){
          this.visible = !this.visible;
        }
      }else {
        this.visible = !this.visible;
      }
    },

  },
}
</script>

<style scoped>
/* 有背景图片 */
.have-background-img{
  background-image: url("@/assets/ERP_images/流程图背景.jpg");
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .show-img div{
    bottom: 30%;
  }
}
</style>